import TruckType from "./TruckType";

class BookingTruckType {
    bookingDate: string;
    truckTypeList: TruckType[];
    constructor(
        bookingDate: string,
        truckTypeList: TruckType[]
    ){
        this.bookingDate = bookingDate;
        this.truckTypeList = truckTypeList;
    }
}
export default BookingTruckType;

import { Button, Descriptions, Divider, Image, Input, Radio, Table, Tag, Avatar, Tooltip, Modal, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import PageHeader from 'src/layouts/PageHeader'
import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { useHistory } from 'react-router';
import { Truck } from 'src/domain/truckDataType';
import classes from "../owner/master.module.css";

import truckService from "src/services/truckService";
import TruckDataInfo from './TruckDataInfo';
import { Link } from 'react-router-dom';
import Section from 'src/components/container/Section';
import { useAppSelector } from 'src/store';


const { Search } = Input;



const columns = [

  {
    title: 'ลำดับ',
    dataIndex: 'seq',
  },

  {
    title: 'ทะเบียนรถ',
    dataIndex: 'registId',
     render: (value: string, item: Truck, index: number) => <Link to={`/app/truck/edit/${item.truckUId}`}>{value}</Link>,
  },
  {
    title: 'ชนิดรถ',
    dataIndex: 'truckTypeName',
    //   align: 'right' as 'right',
    // render: (text: any, record: Truck) => {
    //   return (
    //     <>
    //       <Tooltip title={record.truckTypeName} placement="top">
    //         <Avatar
    //           src={<Image src="https://www.truckmarketthailand.com/asset/images/truck/10wheel.png" />}
    //         />
    //       </Tooltip>

    //     </>
    //   )
    // },
  },
  
  {
    title: 'พนักงานขับรถ',
    dataIndex: 'driverName',
  },

  {
    title: 'ประเภทการวิ่ง',
    dataIndex: 'truckRunningType',
    render: (truckrunning: string) => (
      <>
        <div>
          {(() => {
            if (truckrunning === 'LINEHUAL') {
              return (
                <>{"รถวิ่งระยะไกล"} </>
              )
            }else if  (truckrunning === 'DISTRIBUTE') {
              return (
                <>{"รถวิ่งกระจาย"} </>
              )
            } else {
              return (
                <>{""}</>
              )
            }
          })()}
        </div>
       
      </>
    ),
  },


  {
    title: 'สถานะ',
    key: 'useFlg',
    dataIndex: 'useFlg',
    render: (useFlg: number) => (
      <>
        <div>
          {(() => {
            if (useFlg === 0) {
              return (
                <Tag color="green">{"ใช้งาน"}</Tag>
              )
            } else {
              return (
                <Tag color="red">{"ไม่ใช้งาน"}</Tag>
              )
            }
          })()}
        </div>
       
      </>
    ),
   
  },
];

const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: Truck[]) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
};

const TruckList = () => {

  const history = useHistory();
  // const onSearch = () => { handleSearch() };
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');


  const { sessionUser } = useAppSelector((state) => state.auth);
  const [searchData, setSearchData] = useState<any>("");
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [truckList, setTruckList] = useState<Truck[]>([]);

  useEffect(() => {

    truckService.getTruckByCondition("")
      .then((res) => {
        console.log(res.data)
        setTruckList(res.data)
      })

  }, []);

  useEffect(() => {
    truckService.getTruckByCondition("")
      .then((res) => {
        setTruckList(res.data)
      })
  }, [isModalVisible]);

  // const handleSearch = () => {
  //   console.log(searchData,)

  //   truckService.getTruckByCondition(searchData)
  //     .then((res) => {
  //       console.log(res.data)
  //       setTruckList(res.data)
  //     })
  // }

  const onSearch = (value: any) => {
    console.log("onsearch" ,value)

       truckService.getTruckByCondition(value)
      .then((res) => {
        console.log(res.data)
        setTruckList(res.data)
      })

  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAdd = () => {

    //setIsModalVisible(false);
  };

  return (
    <>
      <PageHeader title="ข้อมูลรถ">

        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          style={{ width: 120 }}
          onClick={() => setIsModalVisible(true)}
        // onClick={() => history.push("/app/truck/new")}
        >
          เพิ่ม
        </Button>

      </PageHeader>

      {/* <Content className="app-page-content" >

        <div className={classes.divList}> */}
      <Section style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>

        <Search placeholder="ค้นหา" onSearch={onSearch} style={{ width: 200 }} />
      </Section>


      <Section style={{ marginTop: 30 }}>
        <Table
          // rowSelection={{
          //   type: selectionType,
          //   ...rowSelection,
          // }}
          //  columns={columns}
          //  dataSource={data}

          rowKey={(item: Truck) => item.id}
          columns={columns}
          dataSource={truckList}

        // size="small"
        // rowKey={(item: Post) => item.id}
        />
      </Section>

      <Modal title="เพิ่มข้อมูลรถ"
        visible={isModalVisible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
        destroyOnClose
      >
        <div>
          <TruckDataInfo
            id={0}
            userId={sessionUser.userId}
            username={sessionUser.username}
            truckUId={""}
            station={sessionUser.station!}
            isSave={() => {
              // console.log("onNextStep");
              handleCancel();
            }}
          />
        </div>
      </Modal>
    </>
  )
}

export default TruckList


import { Button, Col, Form, Input, message, Row, Space, Table, Upload } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import React from 'react'
import classes from "../mastercss/master.module.css";
import { SaveOutlined, UserOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router';

interface IProps {
    id: any;
    userId: number;
    username: string;
    station: string;
    onNextStep: any;
}

const columnfiles = [
    {
        title: 'id',
        dataIndex: 'id',
    },
    {
        title: 'ชื่อไฟล์',
        dataIndex: 'shortName',
    },
    {
        title: 'หมายเหตุ',
        dataIndex: 'tel',
    },
    {
        title: 'ไฟล์',
        dataIndex: 'taxNo',
    },
];

const props_load = {
    name: 'file',
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
        authorization: 'authorization-text',
    },
    onChange(info: any) {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};


const onFinish = async (values: any) => {

    // const registBusiness_: Business = {

    //     id: id,
    //     prenameId: values.prenameCropId,
    //     shortName: values.shortName,
    //     businesstName: values.businessName,
    //     cropId: values.cropId,
    //     address: values.address,
    //     addrPath: addsPathData,
    //     locationCode: locationCode,
    //     zipcode: zipCodeData,
    //     tel: values.tel,
    //     contactPrename: values.contactPrename,
    //     contactName: values.contactName,
    //     contactTel: values.contactTel,
    //     email: values.email,
    //     //   custType ?: String;
    //     //   useFlg ?: number;
    //     userId: userId,
    //     username: username,

    // };

    // console.log(registBusiness_);


    // if (typeof (id) === "undefined" || id === 0) {


    //     try {
    //         const customerId = customerBusinessService.addCustomer(registBusiness_).then(res => {
    //             // history.replace("/app/business/list/");
    //             message.success("save success", 1.5);
    //             onNextStep(true);
    //             id(customerId);
    //             console.log("save " + props.id, props.onNextStep);
    //         }).catch(err => {
    //             console.log(err);
    //         });
    //     } catch (err) {
    //         showMessage(err);
    //     }
    // }

    // if (id !== 0) {
    //     customerBusinessService.updateCustomer(registBusiness_).then(res => {
    //         history.replace("/app/business/list/");
    //         message.success("update complete", 1.5);
    //     }).catch(err => {
    //         console.log(err)
    //     });
    // }
    // setTrigger(false);
};

const BusinessFileInfo: React.FC<IProps> = (props) => {

    const { id, userId, username, station, onNextStep } = props;
    console.log("BusinessFileInfo props" + props.id)

    const history = useHistory();
    const [form] = Form.useForm();

    return (
        <>
            <Form
               form={form}
               //  layout="vertical"
               onFinish={onFinish}
           // onFinishFailed={onFinishFailed}
            >
                <Row>
                    <Col span={24}>
                        <div className={classes.divAdd2}
                        // style={{ width: "98%", height: "500px" }}
                        >
                            <Row style={{ marginTop: "2em", }} gutter={8}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>ชื่อเอกสาร :</span> </Col>
                                <Col span={6} >
                                    <Form.Item
                                        required tooltip="This is a required field">
                                        <Input placeholder="" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}
                                // style={{ border: "1px solid red" }}
                                ></Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>คำอธิบาย :</span> </Col>
                                <Col span={8} >
                                    <Form.Item
                                        required tooltip="This is a required field">
                                        <TextArea
                                            showCount maxLength={100}
                                            rows={4}
                                        // autoSize={{ minRows: 3, maxRows: 5 }}
                                        //    onChange={onChange} 
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}
                                // style={{ border: "1px solid red" }}
                                ></Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>ไฟล์แนบ :</span> </Col>
                                <Col span={8} >
                                    <Form.Item>
                                        {/* required tooltip="This is a required field"> */}
                                        <Upload {...props_load}>
                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                                <Col span={4}
                                // style={{ border: "1px solid red" }}
                                ></Col>
                            </Row>
                            <Row style={{ marginBottom: "2em", }}>
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <Space >
                                        <Button
                                            size="middle"
                                            type="primary"
                                            icon={<SaveOutlined />}
                                        // onClick={() => setTrigger(true)}
                                        >
                                            บันทึก
                                                        </Button>
                                        <Button
                                            size="middle"
                                            type="primary"
                                            icon={<SaveOutlined />}
                                        // onClick={() => setTrigger(true)}
                                        >
                                            ยกเลิก
                                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "3em" }}>
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <Table
                                        columns={columnfiles}
                                    // dataSource={data} 
                                    >

                                    </Table>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1em" }}></Row>
                        </div></Col>
                </Row>
            </Form>


        </>
    )
}

export default BusinessFileInfo

import http from "../lib/http";
import { rootUrl } from "./baseUrl";
// const url = "http://localhost:8080/truck/";
const url = rootUrl("FLEET")+"truck/"

const getAllTruck = () => {
  return http.get(url);
};

const getByTruckUId = (truckUId: string) => {
  console.log("byTruckUId" + truckUId)
  return http.get(url+"byTruckUId/"+truckUId);
};

const getTruckById = (id: number) => {
  console.log("getTruckById" + id)
  return http.get(url+"byId/"+id);
};

const getTruckByCondition = (searchData:string) => {
  console.log(searchData)
  if(searchData === null || searchData === ''){
    searchData = 'null'
  }
  // console.log(url + "truckListByConditionData/"+ searchData)
  return http.get(url + "truckListByConditionData/" + searchData);
};

const updateTruck = (Truck: any) => {
  console.log("updateTruck",Truck)
  return http.post(url + "updateTruck/", Truck);
};


const addTruck = (Truck: any) => {
  console.log(url+"addTruck")
  return http.post(url+"addTruck", Truck);
};

//////////truck type/////////////////

const getTruckTypeById = (id: number) => {
  console.log("getTruckTypeById" + id)
  return http.get(url+"truckTypeById/"+id);
};

const getTruckTypeByName = (data:string) => {
  return http.get(url + "/truckTypeByName/" + data);
};

const getAllTruckType = () => {
  return http.get(url+"/allTruckType");
  // return subjectData;
};

//////////driver//////////
const getDriver = (parentId : any ,name : string) => {
  const param = `parentId=${parentId}&name=${name}`;
  return http.get(url+"/driver/?"+param);
}


//////////partner//////////
const getAllPartner = () => {
  return http.get(url+"/allPartner");
}

const getPartnerIsUse = () => {
  return http.get(url+"/partnerIsUse");
}

const getPartnerByName = (name:string) => {
  return http.get(url+"/partnerByName/"+name);
}

const getPartnerByPartnerUid = (partnerUId:string) => {
  return http.get(url+"/partnerByPartnerUId/"+partnerUId);
}

/////////driver////////////
const getAllDriver = () => {
  return http.get(url+"/allDriver");
}

const getDriverByName = (name:string) => {
  return http.get(url+"/driverByName/"+name);
}

const getDriverByPartnerUId = (partnerUId:string) => {
  return http.get(url+"/driverByPartnerUId/"+partnerUId);
}

const getDriverByDriverUId = (driverUId:string) => {
  return http.get(url+"/driverByDriverUId/"+driverUId);
}

const getTruckStatus = () => {
  console.log("getTruckStatus")
  return http.get(url+"/truckStatus");
};

const getTruckBrandList = () => {
  console.log("truckBrandList")
  return http.get(url+"/truckBrandList");
};

const getTruckStyleList = () => {
  console.log("truckStyleList")
  return http.get(url+"/truckStyleList");
};


////

const updateTapTruckDetail = (Truck: any) => {
   console.log("updateTapTruckDetail",Truck)
  return http.post(url + "updateTapTruckDetail/", Truck);
};

///////////truckTax//////////////

const addTapTruckTax = (TruckTax: any) => {
  console.log("addTapTruckTax",TruckTax)
 return http.post(url + "addTruckTax/", TruckTax);
};

const updateTapTruckTax = (TruckTax: any) => {
  console.log("updateTruckTax",TruckTax)
 return http.post(url + "updateTruckTax/", TruckTax);
};

const getTruckTaxList = (truckId:string) => {
  return http.get(url+"/trucktaxList/"+truckId);
}

const getTruckTaxById = (id:any) => {
  return http.get(url+"/truckTaxById/"+id);
}

////////////////////////////////

////////////TruckAct////////////
const addTapTruckAct = (TruckAct: any) => {
  console.log("addTapTruckAct",TruckAct)
 return http.post(url + "addTruckAct/", TruckAct);
};

const updateTapTruckAct = (TruckAct: any) => {
  console.log("updateTruckAct",TruckAct)
 return http.post(url + "updateTruckAct/", TruckAct);
};

const getTruckActList = (truckId:string) => {
  return http.get(url+"/truckActList/"+truckId);
}


const getInsCompanyList = () => {
  console.log("getInsCompanyList")
  return http.get(url+"/insCompanyList");
};

const getInsTypeList = () => {
  console.log("getInsTypeList")
  return http.get(url+"/insTypeList");
};
// const getTruckActById = (id:any) => {
//   return http.get(url+"/truckTaxById/"+id);
// }
////////////////////////////////

////////////TruckInsurance////////////
const addTapTruckInsurance = (TruckInsurance: any) => {
  console.log("addTapTruckInsurance",TruckInsurance)
 return http.post(url + "addTruckInsurance/", TruckInsurance);
};

const updateTapTruckInsurance = (TruckInsurance: any) => {
  console.log("updateTruckInsurance",TruckInsurance)
 return http.post(url + "updateTruckInsurance/", TruckInsurance);
};

const getTruckInsuranceList = (truckId:string) => {
  return http.get(url+"/truckInsuranceList/"+truckId);
}

////////////////////////////////


export default {
    getAllTruck,
    getTruckById,
    updateTruck,
    addTruck,
    getTruckByCondition,
    getTruckTypeById,
    getTruckTypeByName,
    getAllTruckType,
    getDriver,
    getAllPartner,
    getPartnerByName,
    getPartnerByPartnerUid,
    getDriverByPartnerUId,
    getDriverByDriverUId,
    getTruckStatus,
    getTruckBrandList,
    getTruckStyleList,
    updateTapTruckDetail,
    addTapTruckTax,
    updateTapTruckTax,
    getTruckTaxList,
    getTruckTaxById,
    addTapTruckAct,
    updateTapTruckAct,
    getTruckActList,
    getInsCompanyList,
    addTapTruckInsurance,
    updateTapTruckInsurance,
    getTruckInsuranceList,
    getInsTypeList,
    getPartnerIsUse,
    getByTruckUId
};
import { Button, Col, Form, Input, message, Row, Space, Typography } from 'antd'
import classes from "../mastercss/master.module.css";
import React, { useEffect, useState } from 'react'
import { SaveOutlined, UserOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router';
import { SecUser } from 'src/domain/secUserType';
import { showMessage } from 'src/utils';
import customerBusinessService from "src/services/customerBusinessService";
import { Business } from 'src/domain/businessType';

interface IProps {
    id: any;
    userId: number;
    username: string;
    station: string;
    onNextStep?: any;
}

const BusinessUserInfo: React.FC<IProps> = (props) => {

    const { id, userId, username, station, onNextStep } = props;

    const history = useHistory();
    const [form] = Form.useForm();
    // const secUserId = 0;
    const [business, setBusiness] = useState<Business>({} as Business);

    const [secUser, setSecUser] = useState<SecUser>({} as SecUser);

    useEffect(() => {

        console.log("props BusinessUserInfo >>" + id)
        customerBusinessService.getCustomerById(Number(props.id))
            .then((res) => {
                // console.log("busines >>>>", res)
                setBusiness(res.data)
            })
        // console.log("business search", business)

        customerBusinessService.getSecUserByCustomerId(Number(props.id))
            .then((res) => {
                // console.log("secUer >>>>", res)
                setSecUser(res.data)
            })
        // console.log("secUser", secUser)


    }, []);

     console.log("business search", secUser)

    useEffect(() => {
        // console.log("secUser >>>>", secUser)
        if (id === 0) {

        } else {
            // setAction(true);
            console.log("set from")
            form.setFieldsValue({

                id: secUser.id,
                fullname: secUser.firstName,
                username: secUser.username,
                password: secUser.password,
                confirmPassword:secUser.password,
                email: secUser.email,
                userUId: secUser.userUId,
                tel: secUser.tel,
                custUId: secUser.custUId,

                userIdLogin: userId,
                usernameLogin: username,

            });
            console.log(form);
          
        }

    }, [secUser]);

    // console.log(secUser)
   

    const onFinish = async (values: any) => {

        const secUser_: SecUser = {

            id: secUser.id,
            fullname: business.firstName,
            username: values.username,
            password: values.password,
            email: values.email,
            userUId: secUser.userUId,
            tel: business.tel,
            custUId: business.custUId,

            userIdLogin: userId,
            usernameLogin: username,
            savStation: station,
        };

         console.log(station);


        if (typeof (secUser_.id) === "undefined" || secUser_.id === 0) {

            console.log("BusinessUserInfo save ", secUser_);

            try {
                customerBusinessService.addUser(secUser_).then(res => {
                    // history.replace("/app/business/list/");
                    message.success("save success", 1.5);
                }).catch(err => {
                    console.log(err);
                });
            } catch (err:any) {
                showMessage(err);
            }
        }

        if (secUser.id !== 0) {
            try {
                // customerBusinessService.addUser(secUser_).then(res => {
                //     // history.replace("/app/business/list/");
                //     message.success("save success", 1.5);
                // }).catch(err => {
                //     console.log(err);
                // });
            } catch (err:any) {
                showMessage(err);
            }
            // customerBusinessService.updateCustomer(registBusiness_).then(res => {
            //     history.replace("/app/business/list/");
            //     message.success("update complete", 1.5);
            // }).catch(err => {
            //     console.log(err)
            // });
        }
        // setTrigger(false);
    };


    return (
        <>
            <Form
                form={form}
                //  layout="vertical"
                onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            >
                <Row>
                    <Col span={24}>
                        <div className={classes.divAdd2}
                            style={{ width: "98%", height: "500px" }}
                        >
                            <Row style={{ marginTop: "2em", }} gutter={10}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>Email :</span> </Col>
                                <Col span={6} >
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                              type: 'email',
                                              required: true,
                                              message: 'กรอกอีเมล์ให้ถูกต้อง ',
                                            },
                                          ]}
                                        required tooltip="This is a required field">
                                        <Input
                                            placeholder=""
                                        // name="email"
                                        // value={secUser.email || ''}
                                        // onChange={e => setSecUser({ ...secUser, email: e.target.value })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}
                                // style={{ border: "1px solid red" }}
                                ></Col>
                            </Row>
                            <Row gutter={10}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>Username :</span> </Col>
                                <Col span={6} >
                                    <Form.Item
                                        name="username"
                                        required tooltip="This is a required field">
                                        <Input
                                            placeholder=""
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}
                                // style={{ border: "1px solid red" }}
                                ></Col>
                            </Row>
                            <Row gutter={10}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>Password :</span> </Col>
                                <Col span={6} >
                                    <Form.Item
                                        name="password"
                                        // label="Password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                            ]}
                                         hasFeedback
                                    >
                                    <Input.Password />
                                    </Form.Item>
                                </Col>
                                <Col span={4}
                                // style={{ border: "1px solid red" }}
                                ></Col>
                            </Row>
                            <Row gutter={10}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>Confirm Password :</span> </Col>
                                <Col span={6} >
                                   
                                     <Form.Item
        name="confirmPassword"
        // label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('password ไม่ตรงกัน'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
                                </Col>
                                <Col span={4}
                                // style={{ border: "1px solid red" }}
                                >
                                    <Typography.Link href="#API">รีเซต</Typography.Link>

                                </Col>
                            </Row>
                            <Row style={{ marginBottom: "2em", }}>
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <Space >
                                        <Button
                                            size="middle"
                                            type="primary"
                                            icon={<SaveOutlined />}
                                            onClick={() => { form.submit() }}
                                        // onClick={() => handleClick(true)}
                                        >
                                            บันทึก
                                                        </Button>
                                        <Button
                                            size="middle"
                                            type="primary"
                                            icon={<SaveOutlined />}
                                        // onClick={() => setTrigger(true)}
                                        >
                                            ยกเลิก
                                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>


            </Form>
        </>
    )
}

export default BusinessUserInfo

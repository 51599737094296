import { Avatar, Button, Image, Tag,Table, Modal, Space ,Row, Col,} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { Partner } from 'src/domain/partnerType';
import PageHeader from 'src/layouts/PageHeader';
// import classes from "../mastercss/master.module.css";
import { PlusOutlined } from "@ant-design/icons";
import Search from 'antd/lib/input/Search';
// import { useHistory } from 'react-router';

import patnerService from "src/services/partnerService";
import { Link } from 'react-router-dom';
import PartnerDataInfo from './PartnerDataInfo';
import Section from 'src/components/container/Section';
import { useAppSelector } from 'src/store';
import PartnerAddInfo from './PartnerAddInfo';


  const columns = [
    {
        title: 'ลำดับ',
        dataIndex: 'seq',
    },
    {
        title: 'ชื่อเจ้าของรถ',
        dataIndex: 'name',
        render: (value: string, item: Partner, index: number) => <Link to={`/app/partner/edit/${item.partnerUId}`}>{value}</Link>,
    },
    {
        title: 'เบอร์โทร',
        dataIndex: 'tel',
    },
    {
        title: 'เลขที่ผู้เสียภาษี',
        dataIndex: 'cropId',
    },
    {
      title: 'เลขที่บัตรประชาชน',
      dataIndex: 'identityId',
  },
    {
      title: 'วงเงินประกัน',
      dataIndex: 'insuranceAmt',
      align: 'center' as 'center' ,
      render: (value: string, item: any, index: number) => (
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <span>{value}</span>
        </Col>
    )
    },
    {
      title: 'สถานะ',
      dataIndex: 'useFlg',
      render: (useFlg: number) => (
        <>
        <div>
          {(() => {
              if (useFlg ===  0 ) {
                return (
                  <Tag color="green">{"ใช้งาน"}</Tag>
                )
              } else {
               return (
                  <div >
                    <Tag color="red">{"ไม่ใช้งาน"}</Tag>
                  </div>
                )
              }
            })()}
        </div>
      </>
    ),
  },
];


  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Partner[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };

const PartnerList = () => {

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    // const history = useHistory();
    const { sessionUser } = useAppSelector((state) => state.auth);
    const [partnerList, setPartnerList] = useState<Partner[]>([]);
    const [searchData, setSearchData] = useState<any>("");
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');


    useEffect(() => {

      patnerService.getPartnerByCondition("")
      .then((res) => {
        setPartnerList(res.data)
      })
    }, []);

    useEffect(() => {

      patnerService.getPartnerByCondition("")
      .then((res) => {
        setPartnerList(res.data)
      })
    }, [isModalVisible]);

    const onSearch = (value: any) => {
      console.log("onsearch" ,value)

        patnerService.getPartnerByCondition(value)
        .then((res) => {
          setPartnerList(res.data)
        })

  }

    const handleOk = () => {
      setIsModalVisible(false);
  };

  const handleCancel = () => {
      setIsModalVisible(false);
  };


    return (
        <>
         <PageHeader title="ข้อมูลเจ้าของรถ">
         <Space>
            <Button
              size="large"
              type="primary"
              style={{ width: 120 }}
              icon={<PlusOutlined />}
              onClick={() => setIsModalVisible(true)}
            >
                เพิ่ม
            </Button>
            </Space>

        </PageHeader>
        
        <Section style={{ display: "flex", flexDirection: "column",marginTop: 30 }}>
            <Row gutter={[20, 10]}>
                <Search placeholder="ค้นหา" onSearch={onSearch} style={{ width: 200, marginLeft: "1em", }} />
            </Row>
        </Section>

        <Section style={{ marginTop: 30}}>
            <Table 
                style={{marginTop: "1em",}}
                rowKey={(item: Partner) => item.id}
                dataSource={partnerList}
                columns={columns}
                pagination={{ defaultPageSize: 10, showSizeChanger: true,}}
            />     
        </Section>

        <Modal 
            title="เพิ่มข้อมูล เจ้าของรถ "
            visible={isModalVisible}
            footer={null}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1000}
            destroyOnClose
        >
          <div>
            <PartnerAddInfo
              id={0}
              userId={sessionUser.userId}
              username={sessionUser.username}
              station={sessionUser.station!}
              partnerUId={""}
              isSave={() => {handleCancel();}}
              // isSave={() => {console.log("onNextStep");handleCancel();}}
            />
          </div>
        </Modal>
 
       </>
    )
}

export default PartnerList

import http from "../lib/http";
import * as WebConfig from "../constant/WebConfig";
import BookingHead from "src/model/truckmatching/BookingHead";

// const bookingUrl = `${WebConfig.udomitHostUrl}/truckbooking`;
// const truckUrl = `${WebConfig.udomitHostUrl}/truck`;

import { rootUrl } from "./baseUrl";
const bookingUrl = rootUrl("FLEET")+"truckbooking"
const truckUrl = rootUrl("FLEET")+"truck"

const getByBookingDate = (fromdate: string, enddate: string) => {
    return http.get(`${bookingUrl}/byBookingDate/${fromdate}/${enddate}`);
};
const getBookingListByDateTruckType = (bookingDate: string, truckTypeId: number) => {
    return http.get(`${bookingUrl}/byBookingDate/${bookingDate}/${truckTypeId}`);
};
const getTruckByTypeId = (truckTypeId: number) => {
    return http.get(`${bookingUrl}/byTruckTypeId/${truckTypeId}`);
}
const getBookingListByBookingDateTruckTypeId = (bookingDate: string, truckTypeId: number) => {
    return http.get(`${bookingUrl}/bookingList/${truckTypeId}/${bookingDate}`);
}
const getBookingMapByTruck = (truckId: number, startDate: string, endDate: string) => {
    return http.get(`${bookingUrl}/getBookingMapByTruck/${truckId}/${startDate}/${endDate}`);
}
const addTruckBooking = (bookingHead: BookingHead) => {
    return http.post(`${bookingUrl}/addTruckBooking`, bookingHead);
}
const addTruckRequest = (bookingHead: BookingHead) => {
    return http.post(`${bookingUrl}/addTruckRequest`, bookingHead);
}
const getBookingHead = (bookNo: string) => {
    return http.get(`${bookingUrl}/getBookingHead/${bookNo}`);
}
const getTruckTypeById = (truckTypeId: number) => {
    return http.get(`${truckUrl}/truckTypeById/${truckTypeId}`);
}
const search = (searchValue: {startDate: string, endDate: string, customerGroup: string, customerZone: string}) => {
    return http.post(`${bookingUrl}/search`, {}, { params: searchValue });
}
const getPartnerByTruckGroupInBookingHeads = (bookingHeadIdList: number[]) => {
    console.log(`${truckUrl}/getPartnerByTruckGroupInBookingHeads`)
    return http.post(`${truckUrl}/getPartnerByTruckGroupInBookingHeads`, bookingHeadIdList );
}
const getByIdList = (bookingHeadIdList: number[]) => {
    return http.post(`${bookingUrl}/getByIdList`, bookingHeadIdList );
}
const listAllZone = () => {
    return http.get(`${truckUrl}/listAllZone`);
}
const getServerDate = () => {
    return http.get(`${truckUrl}/getCurrentDateStr`);
}

export default {
    getByBookingDate,
    getBookingListByDateTruckType,
    getTruckByTypeId,
    getBookingListByBookingDateTruckTypeId,
    getTruckTypeById,
    getBookingMapByTruck,
    addTruckBooking,
    addTruckRequest,
    getBookingHead,
    search,
    getPartnerByTruckGroupInBookingHeads,
    getByIdList,
    listAllZone,
    getServerDate
}
import { AutoComplete, Button, Col, Form, Image, Input, message, Row, Select, Space, Switch, Table, Tabs, Typography, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router';
import { Business } from 'src/domain/businessType';
import { Prename } from 'src/domain/prenameType';
import classes from "../mastercss/master.module.css";
import customerBusinessService from "src/services/customerBusinessService";
import { showMessage } from 'src/utils';
import { Location } from 'src/domain/locationType';
import { SaveOutlined, CloseOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { SessionUser } from 'src/store/authen/types';


interface IProps {
    // id: any;
    // userId?: number;
    // username: string;
    // station: string;
    isSave?: any;
    sessionUser ?: SessionUser;
}

const BusinessDataInfo: React.FC<IProps> = (props) => {

    // const { id, userId, username, station, sessionUser,isSave } = props;
    const { sessionUser,isSave } = props;
    console.log("props" , sessionUser)
    const userIdData = sessionUser===undefined?0:sessionUser.userId;
    const usernameData = sessionUser===undefined?"":sessionUser.username;
    const stationCode = sessionUser===undefined?"":sessionUser.station;
 
    const prename: Prename[] = [];
    const { Option } = Select;

    const [business, setBusiness] = useState<Business>({} as Business);
    const [addsPathData, setAddsPathData] = useState<any>();

    const [locationCode, setLocationCode] = useState<any>();
    const [zipCodeData, setzipCodeData] = useState<any>();

    const [prenames, setPrenames] = useState<Prename[]>([]);
    const [locations, setLocations] = useState<Location[]>([]);
    const [location, setLocation] = useState<Location>({} as Location);
    const [custUIdData, setcustUIdData] = useState<any>();
    const [action, setAction] = useState(false);
    const history = useHistory();
    const [form] = Form.useForm();


    useEffect(() => {

        customerBusinessService.getCustomerById(Number(userIdData))
            .then((res) => {
                setBusiness(res.data)
            })

        customerBusinessService.getAllPrename().then((res) => {
            setPrenames(res.data)
        })

        customerBusinessService.getLocationAll().then((res) => {
            // console.log(res.data);
            setLocations(res.data)
        })

    }, []);


    useEffect(() => {
        console.log("business >>>>", business)
        console.log(business.addrPath)
        // addressPathth(business.addrPath);
        if (userIdData === 0) {

        } else {
            setAction(true);
            setAddsPathData(business.addrPath);
            setLocationCode(business.locationCode);
            setzipCodeData(business.zipcode);
            setcustUIdData(business.custUId);
            // console.log(business.addrPath);
            // addressPathth(business.addrPath);

            form.setFieldsValue({
                shortName: business.shortName,
                prenameCropId: business.prenameId,
                businessName: business.firstName,
                address: business.address,
                addrPath: business.addrPathStr,//business.addrPath,
                tel: business.tel,
                cropId: business.cropId,
                contactPrename: business.contactPrename,
                contactName: business.contactName,
                contactTel: business.contactTel,
                email: business.email,
                custUId: business.custUId,

            });

            console.log(form);
        }

    }, [business]);

    const onSelectLocation = (data: any) => {
        console.log("onselect > ", data);
        let arr = data.split("/");
        // console.log(arr[3])

        let rs = locations.filter((item: any) =>
            item.zipcode === arr[3]
            && item.province === arr[2]
            && item.amphoe === arr[1]
            && item.tambon === arr[0])

        setAddsPathData(rs[0].provinceCode + "/" + rs[0].amphoeCode + "/" + rs[0].tambonCode);
        setLocationCode(rs[0].tambonCode);
        setzipCodeData(rs[0].zipcode);

    };

    const onFinish = async (values: any) => {

        const registBusiness_: Business = {

            id: userIdData,
            prenameId: values.prenameCropId,
            shortName: values.shortName,
            businesstName: values.businessName,
            cropId: values.cropId,
            address: values.address,
            addrPath: addsPathData,
            locationCode: locationCode,
            zipcode: zipCodeData,
            tel: values.tel,
            contactPrename: values.contactPrename,
            contactName: values.contactName,
            contactTel: values.contactTel,
            email: values.email,
            //   custType ?: String;
            //   useFlg ?: number;
            userIdLogin: userIdData,
            usernameLogin: usernameData,
            savStation: stationCode,

            custUId: custUIdData,

        };

        console.log(registBusiness_);

        if (typeof (userIdData) === "undefined" || userIdData === 0) {


            try {
                const customerId = customerBusinessService.addCustomer(registBusiness_).then(res => {
                    message.success("save success", 1.5);
                    isSave(true);
                }).catch(err => {
                    console.log(err);
                });
            } catch (err: any) {
                showMessage(err);
            }


        }

        if (userIdData !== 0) {
            customerBusinessService.updateCustomer(registBusiness_).then(res => {
                // history.replace("/app/business/list/");
                message.success("update complete", 1.5);
                // isSave(true);
            }).catch(err => {
                console.log(err)
            });
        }
        // setTrigger(false);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                //  layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row>
                    <Col span={24}>
                        <div className={classes.divAdd2}>
                            <Row style={{ marginTop: "2em", }} gutter={10}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>ชื่อย่อ :</span> </Col>
                                <Col span={2} >
                                    <Form.Item
                                        name="shortName"
                                        rules={[{ required: true, message: 'กรอกชื่อเรียก' }]}
                                        required tooltip="This is a required field">

                                        <Input placeholder="" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}
                                // style={{ border: "1px solid red" }}
                                ></Col>
                            </Row>
                            <Row gutter={10}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>ชื่อบริษัท :</span> </Col>
                                <Col span={2} >
                                    <Form.Item
                                        // label="ชื่อบริษัท"
                                        name="prenameCropId"
                                        rules={[{ required: true, message: 'เลือกคำนำหน้า' }]}
                                        required tooltip="This is a required field">
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"

                                        // onChange={handleChangeTruckType}
                                        // filterOption={(input: any, option: any) =>
                                        //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        // }
                                        // filterSort={(optionA, optionB) =>
                                        //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        // }
                                        >

                                            {prenames.map((item) =>
                                                <Option value={item.id} key={item.id} label={item.name}>

                                                    {item.name}

                                                </Option>
                                            )}

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col />
                                <Col span={8} >
                                    <Form.Item
                                        name="businessName"
                                        rules={[{ required: true, message: 'กรอกชื่อบริษัท' }]}
                                        required tooltip="This is a required field">
                                        <Input placeholder="" />
                                    </Form.Item>
                                </Col>
                                {/* <Col span={6}></Col> */}
                            </Row>
                            <Row gutter={10}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>เลขที่ :</span> </Col>
                                <Col span={7} >
                                    <Form.Item
                                        // label="เลขที่"
                                        name="address"
                                        rules={[{ required: true, message: 'กรอกที่อยุ่' }]}
                                        required tooltip="This is a required field">
                                        <Input placeholder="บ้าน/ซอย/ถนน" />
                                    </Form.Item>
                                </Col>
                                <Col />
                            </Row>
                            <Row gutter={10}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>ที่อยู่ :</span> </Col>
                                <Col span={10} >
                                    <Form.Item
                                        name="addrPath"
                                        rules={[{ required: true, message: 'เลือกที่อยู่' }]}
                                        required tooltip="This is a required field">
                                        {/* <Input placeholder="ตำบล/อำเภอ/จังหวัด/รหัสไปรษรีย์" /> */}
                                        {/* <Input.Group compact> */}
                                        <AutoComplete
                                            onSelect={onSelectLocation}
                                            style={{ width: '70%' }}
                                            filterOption={(input: any, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            // filterSort={(optionA, optionB) =>
                                            //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            // }
                                            // optionFilterProp="children"
                                            placeholder="ตำบล/อำเภอ/จังหวัด/รหัสไปรษรีย์"
                                        >
                                            {locations.map((item) =>
                                                <Option
                                                    value={item.addrPath}
                                                    key={item.id}
                                                    label={item.addrPath}>

                                                    {item.addrPath}

                                                </Option>
                                            )}
                                        </AutoComplete>
                                        {/* </Input.Group> */}
                                    </Form.Item>
                                </Col>
                                <Col />
                            </Row>
                            <Row gutter={10}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>เบอร์โทร :</span> </Col>
                                <Col span={4} >
                                    <Form.Item

                                        name="tel"
                                        rules={[{ max: 10, required: true, message: 'กรุณากรอกเบอร์โทรให้ถูกต้อง' }]}
                                        required tooltip="This is a required field">
                                        <Input placeholder="" />
                                    </Form.Item>
                                </Col>
                                <Col />

                            </Row>
                            <Row gutter={10}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>เลขนิติบุคคุล :</span> </Col>
                                <Col span={4} >
                                    <Form.Item
                                        // label="ชื่อบริษัท"
                                        name="cropId"
                                        rules={[{ required: true, message: 'กรอกเลขที่นิติบุคคล' }]}
                                        required tooltip="This is a required field">
                                        <Input placeholder="" />
                                    </Form.Item>
                                </Col>
                                <Col />

                            </Row>
                            <Row gutter={10}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>ชื่อผู้ติดต่อ :</span> </Col>
                                <Col span={2} >
                                    <Form.Item
                                        // label="ชื่อบริษัท"
                                        name="contactPrename"
                                        rules={[{ required: true, message: 'เลือกคำนำหน้าผู้ติดต่อ' }]}
                                        required tooltip="This is a required field">
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                        >

                                            {prenames.map((item) =>
                                                <Option value={item.id} key={item.id} label={item.name}>

                                                    {item.name}

                                                </Option>
                                            )}

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col />
                                <Col span={8} >
                                    <Form.Item
                                        name="contactName"
                                        rules={[{ required: true, message: 'กรอกชื่อผู้ติดต่อ' }]}
                                        required tooltip="This is a required field">
                                        <Input placeholder="" />
                                    </Form.Item>
                                </Col>
                                {/* <Col span={6}></Col> */}
                            </Row>
                            <Row gutter={10}>
                                <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                    <span>เบอร์โทรผู้ติดต่อ :</span> </Col>
                                <Col span={4} >
                                    <Form.Item
                                        // label="ชื่อบริษัท"
                                        name="contactTel"
                                        rules={[{ max: 10, required: true, message: 'กรอกเบอร์ผู้ติดต่อ' }]}
                                        required tooltip="This is a required field">
                                        <Input placeholder="" />
                                    </Form.Item>
                                </Col>
                                <Col />

                            </Row>
                            <Row style={{ marginBottom: "2em", }}>
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <Space >
                                        <Button
                                            size="middle"
                                            type="primary"
                                            icon={<SaveOutlined />}
                                            // onClick={() => onNextStep(true)}
                                            onClick={() => { form.submit() }}
                                        // onClick={() => {
                                        //     sayHello();
                                        //     setCount(count + 1);
                                        // }}
                                        >
                                            บันทึก
                                        </Button>
                                        <Button
                                            size="middle"
                                            type="primary"
                                            icon={<CloseOutlined />}
                                        // onClick={() => setTrigger(true)}
                                        >
                                            ยกเลิก
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>


            </Form>


        </>
    )
}

export default BusinessDataInfo

import { Button, Space } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import Flex from "src/components/container/Flex";

/* icon */
import { CloseOutlined } from "@ant-design/icons";
import useSessionUser from '../hooks/useSessionUser';
import DFlex from "../components/container/DFlex";


interface IProps {
  title: string;
  subTitle?: string | React.ReactNode;
  onClose?: () => void;
  children?: React.ReactNode;
}

const PageHeader: React.FC<IProps> = ({ title, subTitle, onClose, children }) => {
  const history = useHistory();
  const sessionUser = useSessionUser();

  const closeHandler = () => {
    if (onClose) onClose();
  };

  return (
    <div
      className="app-page-header"
      // style={{ borderBottom: '1px solid #aaa' }}
    >
      <DFlex type="column" full>

        <div style={{ marginBottom: '5px'}}>
          สาขา : <span style={{ fontWeight: 600}}>HQ</span> -  ผู้ใช้ : <span style={{ fontWeight: 600}}>{sessionUser.username}</span>
        </div>
        <DFlex type="row" justifyContent="space-between" alignItems="center" full>
          <div>
            <h3 className="mb-0 mr-3 ">
              {/* <IntlMessage id={title ? title : "home"} /> */}
              {title ? title : "..."}
            </h3>
            <span style={{ fontSize: '13px'}}>{subTitle}</span>
          </div>

          <div className="d-flex flex-row">
            <div className="action-container">{children}</div>
            <div>
              {onClose && (
                <Button
                  type="text"
                  className="btn-close"
                  size="small"
                  onClick={closeHandler}
                >
                  <CloseOutlined />
                </Button>
              )}
            </div>
          </div>
        </DFlex>


      </DFlex>
    </div>
  );
};

export default PageHeader;

import React, { useState } from 'react'
import { PlusSquareOutlined } from "@ant-design/icons";

const styles:any = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 50,
    },
    preview: {
      marginTop: 10,
      display: "flex",
      flexDirection: "column",
    },
    image: { maxWidth: "100%",
     maxHeight: 200 ,
     border: "2px solid red",
     borderRadius: '8px',
    },
    delete: {
      cursor: "pointer",
      padding: 15,
      background: "red",
      color: "white",
      border: "none",
    },
    inputs : {
      position:"absolute",
      left: "0px",
      height: "100%",
      width: "100%",
      opacity: "0",
      background: "#00f",
      zIndex:"999",
  },
  };

const PartnerAvatar = () => {
    const [selectedImage, setSelectedImage] = useState<any>();

  // This function will be triggered when the file field change
  const imageChange = (e:any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setSelectedImage("");
  };

  return (
    <>
      <div style={styles.container}>
       

        {selectedImage && (
          <div style={styles.preview}>
            <img
              src={URL.createObjectURL(selectedImage)}
              style={styles.image}
              alt="Thumb"
            />
            <button onClick={removeSelectedImage} style={styles.delete}>
              Remove This Image
            </button>
          </div>
        )}

<input style={styles.inputs}
          accept="image/*"
          type="file"
          onChange={imageChange}
        />
        <PlusSquareOutlined />
      </div>
    </>
  );
}

export default PartnerAvatar


import { Button, Checkbox, Col, Form, Row, Space } from 'antd'
import React, { useState } from 'react'
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useForm } from 'antd/es/form/Form';
import { FilterItem } from '../../components/filter/filterItem';

interface IProps {
  onOK:(filterItem:FilterItem)=>void
}


const SearchJobStatusPopup: React.FC<IProps> = (props) => {

  const [form] = Form.useForm()

  const [options, setOptions] = useState<{}[]>([]);
  const plainOptions = ['สร้าง', 'ปฎิบัติงาน', 'ปิด',"ยกเลิก"];

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setOptions([...checkedValues]);
    console.log('options = ', options);
  };

  const handleOnClick =()=>{

    let text = "";
    for (let i = 0; i < options.length; i++) {
      if(i===0){
        text +=  options[i];
      }else{
        text += ","+ options[i] ;
      }

    }
    console.log("",text)

    const filterItem : FilterItem ={
      name:"status",
      message:'สถานะ :'+ text,
      payload:{
        status: options,
      }

    }
    props.onOK(filterItem);
 }

 const onReset = () => {
  form.resetFields();
};

  return (
    <>
     <Form
         // {...formItemLayout}
         // layout={formLayout}
         form={form}
         onFinish={handleOnClick}
         // initialValues={{ layout: formLayout }}
         // onValuesChange={onFormLayoutChange}
      >
     <Form.Item
        // label="Username"
        name="status"
        rules={[{ required: true, message: 'กรุณาเลือกทะเบียนรถ' }]}
      >
          <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>

            <Space direction="vertical">
                {/* {plainOptions.map(()=> */}
                    <Checkbox value={"NEW"} >สร้าง</Checkbox>
                    <Checkbox value={"OPERATE"}>ปฎิบัติงาน</Checkbox>
                    <Checkbox value={"CLOSE"}>ปิด</Checkbox>
                    <Checkbox value={"CANCEL"}>ยกเลิก</Checkbox>
                {/* )} */}

             </Space>



           </Checkbox.Group>
      </Form.Item>

      </Form>
      <Space direction="vertical">
        {/* <Row>
          <Col>
            <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>

            <Space direction="vertical">

                    <Checkbox value={"NEW"} >สร้าง</Checkbox>
                    <Checkbox value={"OPERATE"}>ปฎิบัติงาน</Checkbox>
                    <Checkbox value={"CLOSE"}>ปิด</Checkbox>
                    <Checkbox value={"CANCEL"}>ยกเลิก</Checkbox>
             </Space>
           </Checkbox.Group>
          </Col>
        </Row> */}
        <Row>
          <Col>
          <Space>
            <Button onClick={onReset}>ล้าง</Button>
            <Button type="primary" danger onClick={()=>{form.submit()}}>ok</Button>
          </Space>
          </Col>
        </Row>
      </Space>
    </>
  )
}

export default SearchJobStatusPopup
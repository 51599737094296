import React, { FC } from 'react'
import FilterBody from './FilterBody';

interface FilterContainerProps {
  children: any;
  filterList: any[];
  onDeleteItem: (name: string) => void;
}

const FilterContainer: FC<FilterContainerProps> = (props) => {

  const { children, filterList, onDeleteItem } = props;

  return (
    <div style={{ backgroundColor: '#EDEDED', borderRadius: '8px' }}>
      {children}
      <FilterBody  filterList={filterList} onDeleteItem={onDeleteItem} />
    </div>
  )
}

export default FilterContainer
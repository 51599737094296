import { AutoComplete, Button, Col, Form, Image, Input, message, Modal, Row, Select, Space, Switch, Table, Tabs, Typography, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import classes from "../mastercss/master.module.css";
import { SaveOutlined, UserOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Prename } from 'src/domain/prenameType';
import customerBusinessService from "src/services/customerBusinessService";
import { Business } from 'src/domain/businessType';
import { showMessage } from "src/utils";
import { RouteComponentProps, useHistory } from 'react-router';
import { Location } from 'src/domain/locationType';

import { SecUser } from 'src/domain/secUserType';
import BusinessDataInfo from './BusinessDataInfo';
import BusinessUserInfo from './BusinessUserInfo';
import BusinessHistoryInfo from './BusinessHistoryInfo';
import { SessionState } from 'http2';
import { useAppSelector } from 'src/store';


const { TabPane } = Tabs;
const { TextArea } = Input;


interface IParams {
    id: string;
    userId: string;
    username: string;
    station: string;

}




const columnfiles = [
    {
        title: 'id',
        dataIndex: 'id',
    },
    {
        title: 'ชื่อไฟล์',
        dataIndex: 'shortName',
    },
    {
        title: 'หมายเหตุ',
        dataIndex: 'tel',
    },
    {
        title: 'ไฟล์',
        dataIndex: 'taxNo',
    },
];


// const columnUserHitorys = [
//     {
//         title: 'id',
//         dataIndex: 'id',
//     },
//     {
//         title: 'ชื่อเจ้าของรถ',
//         dataIndex: 'shortName',
//     },
//     {
//         title: 'เบอร์โทร',
//         dataIndex: 'tel',
//     },
//     {
//         title: 'เลขที่ผู้เสียภาษี',
//         dataIndex: 'taxNo',
//     },


// ];

function callback(key: any) {
    console.log(key);
}

const props_load = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
        authorization: 'authorization-text',
    },
    onChange(info: any) {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};

const RegistBusinessForm: React.FC<RouteComponentProps<IParams>> = (props) => {

    // const { trigger, setTrigger } = props;
    const id = Number(props.match.params.id);
    const userId = Number(99);
    const username = "sittiponn";
    const station = '11';

    const {sessionUser} = useAppSelector((state)=> state.auth)
    const prename: Prename[] = [];
    const { Option } = Select;

    const [business, setBusiness] = useState<Business>({} as Business);
    const [custUId, setCustUId] = useState<any>();
    const [addsPathData, setAddsPathData] = useState<any>();
    const [locationCode, setLocationCode] = useState<any>();
    const [zipCodeData, setzipCodeData] = useState<any>();

    const [prenames, setPrenames] = useState<Prename[]>([]);
    const [locations, setLocations] = useState<Location[]>([]);
    const [location, setLocation] = useState<Location>({} as Location);
    const [action, setAction] = useState(false);
    const history = useHistory();
    const [form] = Form.useForm();
    

    const [secUser, setSecUser] = useState<SecUser>({} as SecUser);


    useEffect(() => {

        customerBusinessService.getCustomerById(Number(id))
            .then((res) => {
                setBusiness(res.data)
            })

        customerBusinessService.getAllPrename().then((res) => {
            setPrenames(res.data)
        })

        customerBusinessService.getLocationAll().then((res) => {
            // console.log(res.data);
            setLocations(res.data)
        })

    }, []);


    useEffect(() => {
        console.log("business >>>>", business)
        if (id === 0) {

        } else {
            setAction(true);
            setCustUId(business.custUId);

            form.setFieldsValue({

                shortName: business.shortName,
                prenameCropId: business.prenameId,
                businessName: business.firstName,
                address: business.address,
                addrPath: business.addrPath,
                tel: business.tel,
                cropId: business.cropId,
                contactPrename: business.contactPrename,
                contactName: business.contactName,
                contactTel: business.contactTel,
                email: business.email,

            });

            console.log(form);
        }

    }, [business]);


    const onSelectLocation = (data: any) => {
        console.log("onselect > ", data);
        let arr = data.split("/");
        // console.log(arr[3])

        let rs = locations.filter((item: any) =>
            item.zipcode === arr[3]
            && item.province === arr[2]
            && item.amphoe === arr[1]
            && item.tambon === arr[0])

        setAddsPathData(rs[0].provinceCode + "/" + rs[0].amphoeCode + "/" + rs[0].tambonCode);
        setLocationCode(rs[0].tambonCode);
        setzipCodeData(rs[0].zipcode);

    };


    const onFinish = async (values: any) => {

        const registBusiness_: Business = {

            id: id,
            prenameId: values.prenameCropId,
            shortName: values.shortName,
            businesstName: values.businessName,
            cropId: values.cropId,
            address: values.address,
            addrPath: addsPathData,
            locationCode: locationCode,
            zipcode: zipCodeData,
            tel: values.tel,
            contactPrename: values.contactPrename,
            contactName: values.contactName,
            contactTel: values.contactTel,
            email: values.email,
            //   custType ?: String;
            //   useFlg ?: number;

            userIdLogin: userId,
            usernameLogin: username,
            savStation: station,

        };

        console.log(registBusiness_);

        if (id === 0) {
            try {
                customerBusinessService.addCustomer(registBusiness_).then(res => {
                    history.replace("/app/business/list/");
                    message.success("save success", 1.5);
                }).catch(err => {
                    console.log(err);
                });
            } catch (err: any) {
                showMessage(err);
            }
        }

        // if (id !== 0) {
        //     customerBusinessService.updateCustomer(registBusiness_).then(res => {
        //         history.replace("/app/business/list/");
        //         message.success("update complete", 1.5);
        //     }).catch(err => {
        //         console.log(err)
        //     });
        // }
        // setTrigger(false);
    };

    const handleChange = (e: any) => {
        const val = e.target.value;
        console.log(val);
        // if (val.substr(0, 5) !== "+38(0") {
        //   setValue(mask);
        //   const qwe = e.target;
        //   setTimeout(() => qwe.setSelectionRange(5, 5), 1);
        // } else {
        //   setValue(val);
        // }
    };



    const handleCheckPassword = (e: any) => {
        console.log("handleCheckPassword > ", e.target.value);
    }


    const handleClick = (e: any) => {
        console.log("handleClick > ", e);
        console.log("secUser > ", secUser.id);

        const pass = '';
        const rePass = '';

        if (pass !== rePass) {

        }

        // if (secUser.id === 0) {
        try {
            // secUser.cusId = business.id;
            secUser.userIdLogin = userId;
            secUser.usernameLogin = username;

            customerBusinessService.addUser(secUser).then(res => {
                // history.replace("/app/business/list/");
                message.success("save success", 1.5);
            }).catch(err => {
                console.log(err);
            });
        } catch (err: any) {
            showMessage(err);
        }
        // }




    }

    return (
        <>
            <div className={classes.divAdd}>

                <div style={{
                    padding: "20px",
                    width: "100%",
                }}>

                    <Tabs defaultActiveKey="1" onChange={callback}>

                        <TabPane tab="ข้อมูล" key="1">
                            {/* <Modal> */}
                            <div>
                                <BusinessDataInfo
                                    // id={id}
                                    // userId={userId}
                                    // username={username}
                                    // station={station}
                                    sessionUser={sessionUser}
                                    isSave={(e: any) => {
                                        // console.log("onNextStep");
                                        // onSearch(searchData);
                                        // handleCancel();
                                        console.log("ssssssssssssssssssssss >>>", e)
                                    }
                                    }
                                // onNextStep={() => {
                                //     console.log("onNextStep");
                                //     handleClickNext();
                                // }}
                                />
                            </div>
                            {/* </Modal> */}
                        </TabPane>
                        <TabPane tab="เอกสารแนบ" key="2">
                            <Row>
                                <Col span={24}>
                                    <div className={classes.divAdd2}
                                    // style={{ width: "98%", height: "500px" }}
                                    >
                                        <Row style={{ marginTop: "2em", }} gutter={8}>
                                            <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                                <span>ชื่อเอกสาร :</span> </Col>
                                            <Col span={6} >
                                                <Form.Item
                                                    required tooltip="This is a required field">
                                                    <Input placeholder="" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}
                                            // style={{ border: "1px solid red" }}
                                            ></Col>
                                        </Row>
                                        <Row gutter={8}>
                                            <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                                <span>คำอธิบาย :</span> </Col>
                                            <Col span={8} >
                                                <Form.Item
                                                    required tooltip="This is a required field">
                                                    <TextArea
                                                        showCount maxLength={100}
                                                        rows={4}
                                                    // autoSize={{ minRows: 3, maxRows: 5 }}
                                                    //    onChange={onChange} 
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}
                                            // style={{ border: "1px solid red" }}
                                            ></Col>
                                        </Row>
                                        <Row gutter={8}>
                                            <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                                                <span>ไฟล์แนบ :</span> </Col>
                                            <Col span={8} >
                                                <Form.Item>
                                                    {/* required tooltip="This is a required field"> */}
                                                    {/* <Upload 
                                                    {...props_load} >
                                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                    </Upload> */}
                                                    <Upload  {...props_load}
                                                        accept=".txt, .xls,.csv"
                                                    // showUploadList={false}
                                                    // beforeUpload={file => {
                                                    //     const reader = new FileReader();

                                                    //     reader.onload = (e:any) => {
                                                    //         console.log(e.target.result);
                                                    //     };
                                                    //     reader.readAsText(file);

                                                    //     // Prevent upload
                                                    //     return false;
                                                    // }}
                                                    >
                                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}
                                            // style={{ border: "1px solid red" }}
                                            ></Col>
                                        </Row>
                                        <Row style={{ marginBottom: "2em", }}>
                                            <Col span={24} style={{ textAlign: "center" }}>
                                                <Space >
                                                    <Button
                                                        size="middle"
                                                        type="primary"
                                                        icon={<SaveOutlined />}
                                                    // onClick={() => setTrigger(true)}
                                                    >
                                                        บันทึก
                                                    </Button>
                                                    <Button
                                                        size="middle"
                                                        type="primary"
                                                        icon={<SaveOutlined />}
                                                    // onClick={() => setTrigger(true)}
                                                    >
                                                        ยกเลิก
                                                    </Button>
                                                </Space>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "3em" }}>
                                            <Col span={24} style={{ textAlign: "center" }}>
                                                <Table
                                                    columns={columnfiles}
                                                // dataSource={data} 
                                                >

                                                </Table>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "1em" }}></Row>
                                    </div></Col>
                            </Row>


                        </TabPane>
                        <TabPane tab="บัญชีผู้ใช้" key="3">
                            <BusinessUserInfo
                                id={id}
                                userId={userId}
                                username={username}
                                station={station}
                            />


                        </TabPane>
                        <TabPane tab="ประวัติการใช้บริการ" key="4">
                            <BusinessHistoryInfo
                                id={id}
                                custUId={custUId}
                            />
                            {/* <Row>
                                <Col span={24}>
                                    <div className={classes.divAdd2}
                                        style={{ width: "98%", height: "500px" }}
                                    >
                                        <Row style={{ marginTop: "3em" }}>
                                            <Col span={24} style={{ textAlign: "center" }}>
                                                <Table
                                                    columns={columnUserHitorys}
                                                // dataSource={data} 
                                                >

                                                </Table>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row> */}
                        </TabPane>

                    </Tabs>
                </div>


            </div>
        </>
    )
}

export default RegistBusinessForm

import React, { FC } from 'react'
import DFlex from '../../container/DFlex';


interface FilterHeaderProps {
  children: any;
}

const FilterHeader: FC<FilterHeaderProps> = (props) => {

  const { children } = props;

  return (
    <DFlex type='row' justifyContent='space-between' alignItems='center' full
            style={{ backgroundColor: '#E0E0E0', padding: '8px 10px 8px 10px', borderRadius: '8px' }}>
      {children}
    </DFlex>
  )
}

export default FilterHeader
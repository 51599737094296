import BookingRoute from "./BookingRoute";
import Truck from "./Truck";

class BookingHead {
    id: number;
    bookDate: string;
    bookTime: string;
    bookNo: string;
    distance: number;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    truckTypeId: number;
    truckCode: string;
    registId: string;
    driverCode: string;
    driverName: string;
    bookType: string;
    custType: string;
    paymentTypeId: number;
    serviceAmt: number;
    discountAmt: number;
    totalAmt: number;
    useFlg: number;
    status: string;
    agreementId: number;
    truckTypeName: string;
    countTruckType: number;
    truckId: number;
    createDateTimeTh: string;
    truckList?: Truck[];
    bookingRoutes?: BookingRoute[];
    bookId?:number;
    scopeHeadId?: number;
    jobNo?: string;
    custUId?: string;
    customerName?: string;
    customerRemark?: string;
    truckUId?: number;
    originLocationName?: string;
    destinationLocationName?: string;
    truckRequestHeadId?: number;
    truckRequestHeadDate?: string;
    truckRequestHeadconfirmDetailId?: number; 
    truckRequestHeadCreateUsername?: string;
    constructor(
        id: number,
        bookDate: string,
        bookTime: string,
        bookNo: string,
        distance: number,
        startDate: string,
        startTime: string,
        endDate: string,
        endTime: string,
        truckTypeId: number,
        truckCode: string,
        registId: string,
        driverCode: string,
        driverName: string,
        bookType: string,
        custType: string,
        paymentTypeId: number,
        serviceAmt: number,
        discountAmt: number,
        totalAmt: number,
        useFlg: number,
        status: string,
        agreementId: number,
        truckTypeName: string,
        countTruckType: number,
        truckId: number,
        createDateTimeTh: string,
    ){
        this.id = id;
        this.bookDate = bookDate;
        this.bookTime = bookTime;
        this.bookNo = bookNo;
        this.distance = distance;
        this.startDate = startDate;
        this.startTime = startTime;
        this.endDate = endDate;
        this.endTime = endTime;
        this.truckTypeId = truckTypeId;
        this.truckCode = truckCode;
        this.registId = registId;
        this.driverCode = driverCode;
        this.driverName = driverName;
        this.bookType = bookType;
        this.custType = custType;
        this.paymentTypeId = paymentTypeId;
        this.serviceAmt = serviceAmt;
        this.discountAmt = discountAmt;
        this.totalAmt = totalAmt;
        this.useFlg = useFlg;
        this.status = status;
        this.agreementId = agreementId;
        this.truckTypeName = truckTypeName;
        this.countTruckType = countTruckType;
        this.truckId = truckId;
        this.createDateTimeTh = createDateTimeTh;
    }
}
export default BookingHead;

export enum BookingHeadStatus {
    C00_RESERVE = '00',
    C01_REQUEST = '01',
    C02_CONFIRM = '02',
    C03_JOB_CREATED = '03',
    C04_DRIVER_START = '04',
    C05_DRIVER_CLOSE = '05',
    CC_CANCEL = 'CC'
}

export enum CustomerGroupEnum {
    BB = "BB",
    CB = "CB"
}
export interface ICustomerGroup {
    label: string;
    value: string;
}
export function CustomerGroup(ref: CustomerGroupEnum): ICustomerGroup{
    switch(ref){
        case(CustomerGroupEnum.BB): return {value: CustomerGroupEnum.BB, label: 'ลูกค้าองค์กร'};
        case(CustomerGroupEnum.CB): return {value: CustomerGroupEnum.CB, label: 'ลูกค้านิติบุคคล'};
    }
}
export const CustomerGroupList: ICustomerGroup[] = [
    CustomerGroup(CustomerGroupEnum.BB),
    CustomerGroup(CustomerGroupEnum.CB)
];
// export class CustomerGroup {
//     private static readonly BB  = new CustomerGroup('BB', 'ลูกค้าองค์กร');
//     private static readonly CB = new CustomerGroup('CB', 'ลูกค้านิติบุคคล');
  
//     // private to disallow creating other instances of this type
//     private constructor(public readonly key: string, public readonly value: any) {
//     }

//     static getCustomerGroup = (ref: CustomerGroupEnum) => {
//         switch(ref){
//             case(CustomerGroupEnum.BB): return CustomerGroup.BB;
//             case(CustomerGroupEnum.CB): return CustomerGroup.CB;
//         }
//         return null;
//     }
// }
// status 00 = จอง, 01 = request, 02 = confirm, 03 = createdJob, 04 = driver start, 05 = driver close job, CC = ยกเลิก

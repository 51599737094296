import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, Input, Layout, Table, Tabs, DatePicker, Select } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { 
    JobListReserveTab,
    JobListAcceptTab,
    JobListSentTab,
    JobListConfirmTab,
    JobListOverDueTab,
    JobListCancelTab
} from './JobListTabs';
import BookingHead, { BookingHeadStatus, CustomerGroup, CustomerGroupEnum, CustomerGroupList } from 'src/model/truckmatching/BookingHead';
import Flex from 'src/components/container/Flex';
import moment from 'moment';
import truckMatchService from 'src/services/truckMatchService';
import BookingItem from 'src/model/truckmatching/Booking';
import TestInputTable from './JobListTabs/7TestInputTable';
import RmZone from 'src/model/truckmatching/RmZone';

interface SearchParams {
    bookDate: moment.Moment[],
    customerGroup: string, 
    bookingZone: string
}

interface SelectOption {
    value: string,
    label: string,
}

const sampleCustomers: SelectOption[] = [
    {
        value: "ALL",
        label: "ทั้งหมด",
    },
    ...CustomerGroupList
]
const sampleZones: SelectOption[] = [
    {
        value: "ALL",
        label: "ทั้งหมด",
    },
    // {
    //     value: "00",
    //     label: "ภาคเหนือตอนบน",
    // },
    // {
    //     value: "01",
    //     label: "ภาคเหนือตอนล่าง",
    // },
    // {
    //     value: "02",
    //     label: "ภาคอีสานตอนบน",
    // },
    // {
    //     value: "03",
    //     label: "ภาคอีสานตอนล่าง",
    // },
    // {
    //     value: "04",
    //     label: "ภาคตะวันออก",
    // },
]
const JobListMain = () => {

    const history = useHistory();
    const [form] = Form.useForm();

    const [mainDatas, setMainDatas] = useState<BookingHead[]>([]);
    const [t1ReserveDatas, setT1ReserveDatas] = useState<BookingHead[]>([]);
    const [t2SentDatas, setT2SentDatas] = useState<BookingHead[]>([]);
    const [t3AcceptDatas, setT3AcceptDatas] = useState<BookingHead[]>([]);
    const [t4ConfirmDatas, setT4ConfirmDatas] = useState<BookingHead[]>([]);
    const [t5OverDueDatas, setT5OverDueDatas] = useState<BookingHead[]>([]);
    const [t6CancelDatas, setT6CancelDatas] = useState<BookingHead[]>([]);

    const [customerOptions, setCustomerOptions] = useState<SelectOption[]>([]);
    const [zoneOptions, setZoneOptions] = useState<SelectOption[]>([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState<any>();

    useEffect(() => {
        console.log("first useEffect");
        form.setFieldsValue({
            bookDate: [moment(), moment()],
            customerGroup: "ALL",
            bookingZone: "ALL",
        })
        setCustomerOptions(sampleCustomers);
        (
           async () => {
               let datas = await loadBookingData(moment().format('YYYYMMDD'), moment().format('YYYYMMDD'), 'ALL', 'ALL');
               setMainDatas(datas);
           }
        )();
        (
           async () => {
               let res = await truckMatchService.listAllZone();
               let zoneList: RmZone[] = res.data;
               let comboZoneList: {label: string, value: string}[] = [];
               for(let i = 0; i < zoneList.length; i++){
                    let zone = zoneList[i];
                    comboZoneList.push({label: zone.zonename, value: zone.code});
               }
               setZoneOptions([...sampleZones, ...comboZoneList]);
           }
        )();
    }, []);

    useEffect(() => {
        (async() => {
            let res = await truckMatchService.getServerDate();
            let serverDate = res.data;
            updateReserveDatas(serverDate);
            updateSentDatas(serverDate);
            updateAcceptDatas();
            updateConfirmDatas();
            updateOverDueDatas(serverDate);
            updateCancelDatas();
        })();
    }, [mainDatas]);

    const loadBookingData = async(startDate: string, todate: string, customerGroup: string, customerZone: string) => {
        let res = await truckMatchService.search({startDate: startDate, endDate: todate, customerGroup: customerGroup, customerZone: customerZone});
        // setMainDatas(res.data);
        // let result: BookingHead[] = [];
        // let resData = res.data;
        // console.log("res: ", res);
        // console.log("resData: ", resData);
        // if(resData == null){
        //     return [];
        // }
        // // console.log("resData.lenght: ", resData.length);
        // // for(let i = 0; i < resData.length; i++){
        // //     let dataItem: BookingItem = resData[i];
        // //     result = result.concat(dataItem.bookingHeadList);
        // //     console.log("result: ", result);
        // // }
        // result = resData;
        // console.log("result: ", result);
        return res.data;
    }

    const updateReserveDatas = (serverDate: string) => {
        let result: BookingHead[] = mainDatas.filter((data: BookingHead) => { return data.status == BookingHeadStatus.C00_RESERVE && data.startDate.localeCompare(serverDate) >= 0});
        setT1ReserveDatas(result);
    }
    const updateSentDatas = (serverDate: string) => {
        let result: BookingHead[] = mainDatas.filter((data: BookingHead) => { return data.status == BookingHeadStatus.C01_REQUEST && data.startDate.localeCompare(serverDate) >= 0 });
        setT2SentDatas(result);
    }
    const updateAcceptDatas = () => {
        let result: BookingHead[] = mainDatas.filter((data: BookingHead) => { return data.status == BookingHeadStatus.C02_CONFIRM });
        setT3AcceptDatas(result);
    }
    const updateConfirmDatas = () => {
        let result: BookingHead[] = mainDatas.filter((data: BookingHead) => { return data.status == BookingHeadStatus.C02_CONFIRM });
        setT4ConfirmDatas(result);
    }
    const updateOverDueDatas = (serverDate: string) => {
        let result: BookingHead[] = mainDatas.filter((data: BookingHead) => { 
            return data.status == (BookingHeadStatus.C00_RESERVE || data.status == BookingHeadStatus.C01_REQUEST) && data.startDate.localeCompare(serverDate) < 0
        });
        setT5OverDueDatas(result);
    }
    const updateCancelDatas = () => {
        let result: BookingHead[] = mainDatas.filter((data: BookingHead) => { return data.status == BookingHeadStatus.CC_CANCEL });
        setT6CancelDatas(result);
    }

    const onSelectedData = (updatedSelectedRowKeys: any) => {
        setSelectedRowKeys(updatedSelectedRowKeys);
    }

    const handleOk = async(value: SearchParams) => {
        console.log("handleOk: ", value);
        let searchBookDate: string[] = value.bookDate == null ? [moment().format('YYYYMMDD'), moment().format('YYYYMMDD')] : [value.bookDate[0].format("YYYYMMDD"), value.bookDate[1].format("YYYYMMDD")]
        let datas = await loadBookingData(searchBookDate[0], searchBookDate[1], value.customerGroup, value.bookingZone);
        console.log(datas);
        setMainDatas(datas);
    }

    return (
        <div>
            <PageHeader title="ส่งงานให้ Partner">
                <Button
                    size="small"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => { 
                        console.log("selectedRowKeys >>>> ",selectedRowKeys)
                        history.push(`/app/send2partner/selectpartner`, {state: selectedRowKeys});
                    }} 
                >
                    ส่งข้อมูล
                </Button>
            </PageHeader>
            <Content className="app-page-content" style={{paddingLeft: 5, paddingRight: 5}}>
                <Flex justifyContent="center" alignItems="center">
                    <Form style={{display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'white', borderRadius: 5, margin: 5, padding: 5, alignItems: 'center'}} form={form} onFinish={handleOk}>
                        <div style={{marginTop: 5, display: 'flex', flexDirection: 'row', width: '70%'}}>
                            <div style={{width: '50%'}}>
                                วันที่จอง: 
                                <Form.Item name="bookDate">
                                    <DatePicker.RangePicker 
                                        format="DD/MM/YYYY"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{marginTop: 5, display: 'flex', flexDirection: 'row', width: '70%'}}>
                            <div style={{width: '50%', marginRight: 5}}>
                                กลุ่มลูกค้า:
                                <Form.Item name="customerGroup">
                                    <Select
                                        showSearch
                                        placeholder="เลือกกลุ่มลูกค้า"
                                        optionFilterProp="label"
                                        options={customerOptions}
                                    >
                                    </Select>
                                </Form.Item>
                            </div>
                            <div style={{width: '50%', marginLeft: 5}}>
                                โซนต้นทาง: 
                                <Form.Item name="bookingZone">
                                    <Select
                                        showSearch
                                        placeholder="เลือกโซนต้นทาง"
                                        optionFilterProp="label"
                                        options={zoneOptions}
                                    >
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        <div style={{marginTop: 5, display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center'}}>
                            <Form.Item style={{textAlign: 'center'}}>
                                <Button
                                    size="small"
                                    type="primary"
                                    name="per"
                                    icon={<SearchOutlined />}
                                    onClick={() => {
                                        form.submit();
                                    }}
                                >
                                    ค้นหา
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </Flex>
                <Tabs style={{backgroundColor: 'white', borderRadius: 5, margin: 5, padding: 5}} defaultActiveKey="1" type="card">
                    <Tabs.TabPane tab="จอง" key="1">
                        <JobListReserveTab rowDatas={t1ReserveDatas} onSelected={onSelectedData} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="ส่งงาน" key="2">
                        <JobListSentTab rowDatas={t2SentDatas} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="รับงาน" key="3">
                        <JobListAcceptTab rowDatas={t3AcceptDatas} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="ยืนยัน" key="4">
                        <JobListConfirmTab rowDatas={t4ConfirmDatas} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="งานเกินเวลา" key="5">
                        <JobListOverDueTab rowDatas={t5OverDueDatas} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="ยกเลิก" key="6">
                        <JobListCancelTab rowDatas={t6CancelDatas} />
                    </Tabs.TabPane>
                    {/* <Tabs.TabPane tab="ทดสอบ" key="7">
                        <TestInputTable rowDatas={t1ReserveDatas} />
                    </Tabs.TabPane> */}
                </Tabs>
            </Content>
        </div>
    )
}

export default JobListMain;

import { Col, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { Business } from 'src/domain/businessType';
import classes from "../mastercss/master.module.css";
import customerBusinessService from "src/services/customerBusinessService";
import { Job } from 'src/domain/rangerJobType';

const columnUserHitorys = [
    {
        title: 'ลำดับ',
        dataIndex: 'seq',
    },
    {
        title: 'วันที่จอง',
        dataIndex: 'bookData',
    },
    {
        title: 'วันที่เริ่มใช้งาน',
        dataIndex: 'startDate',
    },
    {
        title: 'วันที่สิ้นสุดการใช้งาน',
        dataIndex: 'endDate',
    },
    {
        title: 'เลขที่งาน',
        dataIndex: 'jobNo',
    },
    {
        title: 'ชนิดรถ',
        dataIndex: 'truckTypeName',
    },

    

];

interface IProps {
    id: any;
    custUId : string;
}

const BusinessHistoryInfo: React.FC<IProps> = (props) => {
    const { id,custUId } = props;

    const [businessList, setBusinessList] = useState<Business[]>([]);
    const [jobList, setJobList] = useState<Job[]>([]);

    useEffect(() => {

        console.log("props BusinessUserInfo >>" + id)
        customerBusinessService.getBusinessJobList(custUId)
            .then((res) => {
                console.log(res.data)
                setJobList(res.data)
            })
    
    }, []);

    return (
        <>
            <Row>
                <Col span={24}>
                    {/* <div className={classes.divAdd2}
                        style={{ width: "98%", height: "500px" }}
                    > */}
                    <div className={classes.divListDetail} >
                        <Row >
                            <Col span={24} style={{ textAlign: "center" }}>
                                <Table
                                    columns={columnUserHitorys}
                                    rowKey={(item: Job) => item.id}
                                    dataSource={jobList}

                                >

                                </Table>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

        </>
    )
}

export default BusinessHistoryInfo

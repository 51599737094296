import http from "../lib/http";
import * as WebConfig from "../constant/WebConfig";
import RmSendBookHeadDTO from "src/model/truckmatching/RmSendBookHeadDTO";
import { rootUrl } from "./baseUrl";

const sendbooking = rootUrl("FLEET")+"/sendbooking"
// const sendbooking = `${WebConfig.udomitHostUrl}/sendbooking`;

const sendJob2Partner = (sendBookingHead: RmSendBookHeadDTO) => {
    return http.post(`${sendbooking}/sendJob2Partner`, sendBookingHead);
}

export default {
    sendJob2Partner
}
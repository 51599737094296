import React, { useEffect, useState } from 'react';
import { Alert, Button, Layout, Table, Tabs, Form, Select } from 'antd';
import { ColumnsType } from "antd/lib/table";
import PageHeader from 'src/layouts/PageHeader';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import BookingHead from 'src/model/truckmatching/BookingHead';
import { TableRowSelection } from "antd/lib/table/interface";
import Flex from 'src/components/container/Flex';
import truckMatchService from 'src/services/truckMatchService';
import { SharingColumn } from './JobListTabs/SharingData';
import PartnerTruckDTO from 'src/model/truckmatching/PartnerTruckDTO';
import TruckTypeDTO from 'src/model/truckmatching/TruckTypeDTO';

const ViewJobPartnerDetail = (props: any) => {
    const simpleTruckTypeOptions = [
        {
            value: "ALL",
            label: "เลือกทั้งหมด"
        }
    ]

    const history = useHistory();

    const [pageSize, setPageSize] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [oriJobList, setOriJobList] = useState<BookingHead[]>([]);
    const [currentJobList, setCurrentJobList] = useState<BookingHead[]>([]);
    const [selectedBookingHeadsId, setSelectedBookingHeadsId] = useState<number[]>([]);
    const [partnerId, setPartnerId] = useState<number>();
    const [truckTypeId, setTruckTypeId] = useState<number>();
    const [truckTypeOptions, setTruckTypeOptions] = useState<any>([]);
    const [selectedTruckType, setSelectedTruckType] = useState<string>("ALL");
    const [selectedPartner, setSelectedPartner] = useState<PartnerTruckDTO>();

    const columns: ColumnsType<BookingHead> = [
        {
            title: "ลำดับ",
            dataIndex: "id",
            key: "id",
            width: 50,
            render: (value: string, item: BookingHead, index: number) => (
                ((currentPage - 1) * pageSize) + (index+1)
            ),
        },
        ...SharingColumn
    ];

    useEffect(() => {
        if(props.history.location.state && props.history.location.state.bookingHeadIds.length > 0){
            setTruckTypeId(props.history.location.state.truckTypeId);
            setPartnerId(props.history.location.state.partnerId);
            setSelectedBookingHeadsId(props.history.location.state.bookingHeadIds);
        }else{
            history.replace("/app/send2partner/list");
        }
    }, []);

    useEffect(() => {
        // let tmpSelectedBookingHeads: BookingHead[] = [];
        (async() => {
            if(selectedBookingHeadsId.length > 0){
                let res = await truckMatchService.getByIdList(selectedBookingHeadsId);
                console.log(res.data);
                let tmpSelectedBookingHeads: BookingHead[] = res.data;
                // resData.filter((value: BookingHead) => {
                //     return value.
                // });
                let partnerRes = await truckMatchService.getPartnerByTruckGroupInBookingHeads(selectedBookingHeadsId);
                let partnerTruckDto: PartnerTruckDTO[] = partnerRes.data;
                let interestPartnerTruckDto: PartnerTruckDTO[] = partnerTruckDto.filter((value: PartnerTruckDTO) => {
                    return value.partner.id == partnerId;
                })
                if(interestPartnerTruckDto && interestPartnerTruckDto.length > 0){
                    let truckTypesInPartner: TruckTypeDTO[] = interestPartnerTruckDto[0].truckTypeList;
                    setSelectedPartner(interestPartnerTruckDto[0]);
                    let truckTypesInPartnerOptions = [...simpleTruckTypeOptions];
                    if(truckTypesInPartner){
                        for(let i = 0; i < truckTypesInPartner.length; i++){
                            truckTypesInPartnerOptions.push({
                                value: "" + truckTypesInPartner[i].truckType.id,
                                label: truckTypesInPartner[i].truckType.typeName
                            });
                        }
                    }
                    setTruckTypeOptions(truckTypesInPartnerOptions);
                    tmpSelectedBookingHeads = tmpSelectedBookingHeads.filter((value: BookingHead) => {
                        let result: any[] = truckTypesInPartnerOptions.filter((truckTypeValue: any) => {
                            return truckTypeValue.value == value.truckTypeId;
                        })
                        return result.length > 0;
                        // return true;
                    });
                    // setCurrentJobList(tmpSelectedBookingHeads);
                    setOriJobList(tmpSelectedBookingHeads);
                    setSelectedTruckType("" + props.history.location.state.truckTypeId);
                }
            }
        })();
    }, [selectedBookingHeadsId])

    // useEffect(() => {
    //     console.log("oriJobList: ", oriJobList);
    // }, [oriJobList]);

    useEffect(() => {
        console.log("selectedTruckType effect: ", selectedTruckType);
        if(selectedTruckType != "ALL"){
            setCurrentJobList(oriJobList.filter((value: BookingHead) => {
                return value.truckTypeId.toString() == selectedTruckType;
            }));
        }else{
            setCurrentJobList(oriJobList);
        }
    }, [selectedTruckType]);

    // useEffect(() => {
    //     setSelectedTruckType("" + props.history.location.state.truckTypeId);
    // }, [truckTypeOptions])

    const onTruckTypeChange = (value: string) => {
        console.log("onTruckTypeChange: ", value);
        setSelectedTruckType(value);
    }

    const [selectedRowKeys, setSelectedRowKeys] = useState<any>();
    const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: BookingHead[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection: TableRowSelection<BookingHead> = {selectedRowKeys, onChange: onSelectChange};
    return (
        <div>
            <PageHeader title="ข้อมูลรายการสำหรับ Partner">
            </PageHeader>
            <Content className="app-page-content" style={{paddingLeft: 5, paddingRight: 5}}>
                <Flex justifyContent="center" alignItems="center">
                    <div style={{marginTop: 5, display: 'flex', flexDirection: 'row', width: '70%'}}>
                        <div style={{display:'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center', width: '50%', marginRight: 5}}>
                            {selectedPartner ? selectedPartner.partner.name : ""}
                        </div>
                        <div style={{display:'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'flex-start', width: '50%', marginLeft: 5}}>
                            ชนิดรถ: 
                                <Select
                                    showSearch
                                    placeholder="เลือกประเภทรถ"
                                    optionFilterProp="label"
                                    options={truckTypeOptions}
                                    style={{marginLeft: 5}}
                                    value={selectedTruckType}
                                    onChange={onTruckTypeChange}
                                >
                                </Select>
                        </div>
                    </div>
                </Flex>
                <Flex flexDirection='column' justifyContent="center" alignItems="center">
                    <div style={{marginTop: 5, display: 'flex', flexDirection: 'row', width: '90%'}}>
                        <Table
                            columns={columns}
                            dataSource={currentJobList}
                            size="small"
                            rowKey={(item: BookingHead) => item.id}
                            // rowSelection={rowSelection}
                            pagination={{current: currentPage, pageSize: pageSize, onChange: (page: number) => {
                                setCurrentPage(page);
                            }}}
                            style={{width: '100%'}}
                        />
                    </div>
                    <div style={{margin: 5}}>
                        {/* <Button
                            size="small"
                            type="primary"
                            // icon={<PlusOutlined />}
                            onClick={() => { history.replace("/app/send2partner/list") }} 
                            >ตกลง
                        </Button> */}
                        &nbsp;
                        <Button
                            size="small"
                            type="default"
                            // icon={<PlusOutlined />}
                            onClick={() => { history.goBack() }} 
                            >ย้อนกลับ
                        </Button>
                    </div>
                </Flex>
            </Content>
        </div>
    )
}

export default ViewJobPartnerDetail;

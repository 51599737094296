import React, { useState,useEffect } from 'react'
import { Button, Col, Input, Row, Tabs, Form, Radio, Table, List, Image, Switch, Select, Typography, Divider, Space, AutoComplete, Card, message } from 'antd'
import { SaveOutlined, UserOutlined, PlusOutlined } from "@ant-design/icons";
import Section from 'src/components/container/Section';
import imageDriver from "../../assets/images/driver.jpg"
import { SecUser } from 'src/domain/secUserType';
import { Driver } from 'src/domain/driverType';
import { showMessage } from 'src/utils';
import driverService from 'src/services/driverService';
// import { useEffect } from 'react-router/node_modules/@types/react';

interface IProps {
    id: any;
    userId: number;
    username: string;
    station: string;
    driverUId?: any;
    // isSave?: any;
}

const DriverUserInfo: React.FC<IProps> = (props) => {

    const { id, userId, username, station, driverUId } = props;

    const [form] = Form.useForm();
    const [secUser, setSecUser] = useState<SecUser>({} as SecUser);
    const [driver, setDriver] = useState<Driver>({} as Driver);
    const [isUseflg, setUseflg] = useState<any>(false);
    const [useflgData, setUseflgData] = useState<any>(0);



    useEffect(() => {
        
        //partnerUId
        //หา  USER_UID จาก sec_userPartner
        
        driverService.getBydrvUUId(driverUId).then((res) => {
            setDriver(res.data)
        })

        driverService.getSecUserDriver(driverUId).then((res) => {
            setSecUser(res.data)
        })

       
        
        // console.log("partner",partner)
        
    }, []);

    //  console.log("business search", secUser)

    useEffect(() => {
        //  console.log("secUserId >>>>", secUser)
        if (Object.keys(secUser).length === 0 ) {

        } else { 
            if(secUser.useFlg === 0) {
                setUseflg(true);
                setUseflgData(secUser.useFlg)
            }   
            
            console.log("secUser",secUser.email)
            
            form.setFieldsValue({

                id: secUser.id,
                fullname: driver.fullname,
                username: secUser.username,
                password: secUser.password,
                comfirmPassword: secUser.password,
                email: secUser.email,
                userUId: secUser.userUId,
                tel: secUser.tel,
                refUId : driverUId,
                
                userIdLogin: userId,
                usernameLogin: username,

            });

            
        }
    }, [secUser]);

    const switchOnChange = (checked: any) => {
        console.log(`switch to ${checked}`);
        setUseflg(checked);
        if (checked === false) {
            setUseflgData(-1)
        } else {
            setUseflgData(0)
        }
        console.log(useflgData);
    };


    const onFinish = async (values: any) => {

        const secUser_: SecUser = {

            id: secUser.id,
            fullname: driver.fullname,
            username: values.username,
            password: values.password,
            email: driver.email,
            userUId: secUser.userUId,
            tel: driver.tel,
            useFlg : useflgData,
            // custUId: business.custUId,
            refUId:driverUId,
            userIdLogin: userId,
            usernameLogin: username,
            savStation : station,
        };

        // if (typeof (secUser_.id) === "undefined" || secUser_.id === 0) {
        console.log(secUser)
        if ( Object.keys(secUser).length === 0) {
            try {
                driverService.addSecUserDriver(secUser_).then(res => {
                    if(res.data.status){
                        message.success("save success", 1.5);
                        // isSave(true);
                    }else{
                        message.error(res.data.message, 1.5);
                    }
                   
                }).catch((err:any) => {
                    console.log(err);
                });

            } catch (err:any) {
                showMessage(err);
            }
        }

        if (Object.keys(secUser).length !== 0) {
            try {
                driverService.updateSecUserDriver(secUser_).then(res => {
                    if(res.data.status){
                        message.success("update success", 1.5);
                        // isSave(true);
                    }else{
                        message.error(res.data.message, 1.5);
                    }
                }).catch(err => {
                    console.log(err);
                });
            } catch (err:any) {
                showMessage(err);
            }
          
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            // initialValues={{ requiredMarkValue: requiredMark }}
            // onValuesChange={onRequiredTypeChange}
            // requiredMark={requiredMark}
            >
                <Row>
                    <Col span={24}>
                        <Row >
                            <Col span={24} >
                                <Section>
                                    {/* <Row gutter={[10, 10]}>
                                        <Col span={20} >
                                            <Form.Item label="ชื่อผู้ใช้"
                                                name="fullname"
                                                rules={[{ required: true, message: 'กรุณากรอกชื่อ' }]}
                                                required tooltip="This is a required field">
                                                <Input placeholder="" />
                                            </Form.Item>
                                        </Col>
                                    </Row> */}
                                    <Row gutter={[10, 10]}>
                                        <Col span={20} >
                                            <Form.Item label="Username"
                                                name="username"
                                                rules={[{ required: true, message: 'กรุณากรอก Username' }]}
                                                required tooltip="This is a required field">
                                                <Input placeholder="" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={20} >
                                            <Form.Item label="Password"
                                                name="password"
                                               
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'กรุณากรอก password!',
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <Input.Password />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={20} >
                                            <Form.Item label="Confirm Password"
                                                name="comfirmPassword"
                                                // label="Confirm Password"
                                                dependencies={['password']}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'กรุณายืนยัน password!',
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('password ไม่ตรงกัน'));
                                                        },
                                                    }),
                                                ]}>
                                                <Input.Password />
                                            </Form.Item>
                                        </Col> 
                                    </Row>
                                    <Row>
                                        <Col span={17}></Col>
                                        <Col span={4} >
                                            <Button
                                                size="large"
                                                type="primary"
                                                icon={<SaveOutlined />}
                                                style={{ width: 120 }}
                                                onClick={() => { form.submit() }}
                                            >
                                                เพิ่ม
                                            </Button>
                                        </Col>
                                    </Row>
                                </Section>
                            </Col>
                            {/* <Col span={1}></Col>
                            <Col span={8}>
                                <Section>
                                    <Section>
                                        <Row >
                                            <Col span={1}></Col>
                                            <Col span={22} style={{ marginTop: "1em", alignItems: "center" }} >
                                                <Image style={{ height: "250px", width: "250px" }} src={imageDriver} />
                                            </Col>
                                            <Col span={1}></Col>
                                        </Row>
                                        <Row style={{ marginTop: "1em" }}>
                                            <Col span={24}>
                                                <Row>
                                                    <Col span={7} style={{ textAlign: "right" }}>
                                                        <span >รหัส :</span>
                                                    </Col>
                                                    <Col span={1}></Col>
                                                    <Col span={14}>
                                                        <span >{driverUId}</span>
                                                    </Col>
                                                    <Col span={2}></Col>
                                                </Row>
                                                <Row style={{ marginTop: "1em", marginBottom: "1em" }}>
                                                    <Col span={7} style={{ textAlign: "right" }}>
                                                        <span>สถานะ :</span>
                                                    </Col>
                                                    <Col span={1}></Col>
                                                    <Col span={14} >
                                                        <Switch onChange={switchOnChange} checked={isUseflg} />
                                                    </Col>
                                                    <Col span={2}></Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Section>
                                </Section>
                            </Col> */}
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default DriverUserInfo
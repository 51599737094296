import { Button, Col, Input, Row, Tabs, Form, Radio, Image, Switch, Typography, Divider, message, Select, Table, Upload } from 'antd'
import classes from "../mastercss/master.module.css";
import React, { useEffect, useState } from 'react'
import truckService from 'src/services/truckService';
import { Truck } from 'src/domain/truckDataType';
import { useHistory } from 'react-router';
import { TruckType } from 'src/domain/truckSelectType';
import { Partner } from 'src/domain/partnerType';
import { showMessage } from 'src/utils';
import { SaveOutlined, UserOutlined, PlusOutlined } from "@ant-design/icons";
import { Driver } from 'src/domain/driverType';
import { ReferenceDetail } from 'src/domain/referenceDetailType';
import Section from 'src/components/container/Section';

interface IProps {
    id: any;
    userId: number;
    username: string;
    station: string;
    isSave?: any;
    truckUId?: any;
}

const TruckDataInfo: React.FC<IProps> = (props) => {

    const { id, userId, username, station, isSave,truckUId } = props;

    const { Option } = Select;
    const [form] = Form.useForm();
    const [truck, setTruck] = useState<Truck>({} as Truck);
    const [trucktypes, setTrucktypes] = useState<TruckType[]>([]);
    const [partners, setPartners] = useState<Partner[]>([]);
    const [drivers, setDrivers] = useState<Driver[]>([]);
    const [referenceDetails, setReferenceDetails] = useState<ReferenceDetail[]>([]);
    const [truckRunningType,setTruckRunningType] = useState<string>("");
    const [isUseflg, setUseflg] = useState<any>(false);
    const [useflgData, setUseflgData] = useState<any>(0);


    useEffect(() => {
      
        truckService.getByTruckUId(truckUId).then((res) => {
            setTruck(res.data)
        })
             
        console.log(truck.registId,)

        truckService.getAllTruckType().then((res) => {
            setTrucktypes(res.data)
        })

        truckService.getPartnerIsUse().then((res) => {
            setPartners(res.data)
        })

        truckService.getTruckStatus().then((res) => {
            setReferenceDetails(res.data)
        })

    }, []);

    useEffect(() => {
        
        if (truckUId === "") {
            console.log(truckUId)
        } else {

            if(truck.useFlg === 0) {
                setUseflg(true);
                setUseflgData(truck.useFlg)
            }

            form.setFieldsValue({
                registId: truck.registId,
                truckTypeId: truck.truckTypeId,
                partnerUId: truck.partnerUId,
                driverUId: truck.driverUId,
                status: truck.status,
                truckRunningType : truck.truckRunningType,
            });


            truckService.getPartnerIsUse().then((res) => {
                setPartners(res.data)
            })


            truckService.getDriverByPartnerUId(String(truck.partnerUId)).then((res) => {
                setDrivers(res.data)
            })

            truckService.getTruckStatus().then((res) => {
                setReferenceDetails(res.data)
            })

            console.log(form);
        }

    }, [truck]);


    const switchOnChange = (checked:any) => {
        console.log(`switch to ${checked}`);
        setUseflg(checked);
        if(checked === false){
            setUseflgData(-1)
        }else{
            setUseflgData(0)
        }
        console.log(useflgData);
    };

    const onFinish = async (values: any) => {
        console.log("Succes :", values);
        const _truck: Truck = {

            id: id,
            registId: values.registId,
            truckTypeId: values.truckTypeId,
            partnerUId: values.partnerUId,
            driverUId: values.driverUId,
            status: values.status,
            truckRunningType : values.truckRunningType,
            truckUId : truckUId,

            userIdLogin: userId,
            usernameLogin: username,
            savStation: station,

        };

        console.log(_truck);

        if (truckUId === "" ) {

            try {
                truckService.addTruck(_truck).then(res => {
                    // console.log("addTruck",res);
                    if(res.data.status){
                        message.success("save success", 1.5);
                        isSave(true);
                    }else{
                        message.error(res.data.message, 1.5);
                    }  
                }).catch(err => {
                    console.log(err);
                });
            } catch (err: any) {
                showMessage(err);
            }

        } else if (truckUId !== "" ) {
            truckService.updateTruck(_truck).then(res => {
                if(res.data.status){
                    message.success("update complete", 1.5);
                }else{
                    message.error(res.data.message, 1.5);
                }
            }).catch(err => {
                console.log(err)
            });
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    function handleChangeTruckType(values: number) {
        const _truckTypeId = values;
        console.log("truckType >>> ", values, _truckTypeId)

        truckService.getPartnerIsUse().then((res) => {
            setPartners(res.data)
        })

    }

    function handleChangePartner(value: string) {
        const _partnerUId = value;
        console.log("partnerUId >>> ", value, _partnerUId)

        truckService.getDriverByPartnerUId(value).then((res) => {
            setDrivers(res.data)
        })

    }

    function handleChangeDriver(value: string) {
        // const _partnerUId = value;
        // console.log("partnerUId >>> ", value, _partnerUId)

        truckService.getTruckStatus().then((res) => {
            setReferenceDetails(res.data)
        })

        console.log(referenceDetails)
    }


    function handleTruckRunningType(value : string){
        setTruckRunningType(value);
    }


    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
            >

                <Row>
                    <Col span={24}>

                        {/* <div className={classes.divAdd2}> */}
                        <Section>
                            <Row >
                                <Col span={2}></Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="ทะเบียนรถ"
                                        name="registId"
                                        rules={[{ required: true, message: 'กรอกทะเบียนรถ' }]}
                                    >
                                        <Input placeholder="" />
                                    </Form.Item>
                                </Col>
                                <Col span={10} style={{paddingLeft : "10px"}}>
                                    <Form.Item
                                        label="ชนิดรถ"
                                        name="truckTypeId"
                                        rules={[{ required: true, message: 'เลือกชนิดรถ' }]}
                                    // required tooltip="This is a required field"
                                    >

                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            onChange={handleChangeTruckType}
                                            filterOption={(input: any, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >

                                            {trucktypes.map((item) =>
                                                <Option value={item.id} key={item.id} label={item.typeName}>

                                                    {item.typeName}

                                                </Option>
                                            )}

                                        </Select>

                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row >
                                <Col span={2}></Col>
                                <Col span={20}>
                                    <Form.Item
                                        label="เจ้าของรถ"
                                        name="partnerUId"
                                        rules={[{ required: true, message: 'เลือกเจ้าของรถ' }]}
                                    // required tooltip="This is a required field"
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            onChange={handleChangePartner}
                                            filterOption={(input: any, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {/* <Option value="1">Not Identified</Option>
                                                            <Option value="2">Closed</Option>
                                                            <Option value="3">Communicated</Option>
                                                            <Option value="4">Identified</Option>
                                                            <Option value="5">Resolved</Option>
                                                            <Option value="6">Cancelled</Option> */}
                                            {partners.map((item) =>
                                                <Option value={item.partnerUId} key={item.id} label={item.name}>

                                                    {item.name}

                                                </Option>
                                            )}

                                        </Select>

                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row  >
                                <Col span={2}></Col>
                                <Col span={20}>
                                    <Form.Item
                                        label="พนักงานขับรถ"
                                        name="driverUId"
                                        rules={[{ required: true, message: 'เลือกพนักงานขับรถ' }]}
                                    // required tooltip="This is a required field"
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Search to Select"
                                            onChange={handleChangeDriver}
                                            optionFilterProp="children"
                                            filterOption={(input: any, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >

                                            {drivers.map((item) =>
                                                <Option value={item.driverUId} key={item.id} label={item.firstname + " " + item.lastname}>

                                                    {item.firstname + " " + item.lastname}

                                                </Option>
                                            )}

                                        </Select>

                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row >
                                <Col span={2}></Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="ประเภทการวิ่ง"
                                        name="truckRunningType"
                                        rules={[{ required: true, message: 'เลือกประเภทการวิ่ง' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            value={truckRunningType}
                                            onChange={handleTruckRunningType}
                                            filterOption={(input: any, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            <Option value="LINEHUAL">วิ่งระยะไกล</Option>
                                            <Option value="DISTRIBUTE">วิ่งกระจาย</Option>
                        

                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col span={10} style={{paddingLeft : '10px'}}>
                                    <Form.Item
                                        label="สถานะรถ"
                                        name="status"
                                        rules={[{ required: true, message: 'เลือกสถานะรถ' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                        >

                                            {referenceDetails.map((item) =>
                                                <Option value={item.code} key={item.code} label={item.name}>

                                                    {item.name}

                                                </Option>
                                            )}

                                        </Select>

                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={17}></Col>
                                <Col span={4}>
                                    <Button
                                        size="large"
                                        type="primary"
                                        icon={<SaveOutlined />}
                                        style={{ width: 120 }}
                                        onClick={() => { form.submit() }}
                                    //  onClick={() => setTrigger(true)}
                                    >
                                        บันทึก
                                    </Button>
                                </Col>
                                <Col span={1}></Col>
                            </Row>
                      
                        </Section>
                    </Col>
                    {/* <Col span={1}></Col>
                    <Col span={8}>
                    <Section>
                            <Row style={{ marginTop: "2em", }}>
                                <Col span={1}></Col>
                                <Col span={22}  >
                                    <Form.Item required tooltip="This is a required field" >
                                        <Image
                                            width={250} height={130}
                                            src="https://www.truckmarketthailand.com/asset/images/truck/10wheel.png" />
                                    </Form.Item>

                                </Col>
                                <Col span={1}></Col>
                            </Row>
                            <Row style={{ marginTop: "1em" }}>
                                <Col span={24}>
                                    <Row>
                                        <Col span={7} style={{ textAlign: "right" }}>
                                            <span >รหัส :</span>
                                        </Col>
                                        <Col span={1}></Col>
                                        <Col span={14}>

                                            <span >{truckUId}</span>
                                        </Col>
                                        <Col span={2}></Col>
                                    </Row>
                                    <Row style={{ marginTop: "1em", marginBottom: "1em" }}>
                                        <Col span={7} style={{ textAlign: "right" }}>
                                            <span>สถานะ :</span>
                                        </Col>
                                        <Col span={1}></Col>
                                        <Col span={14} >
                                        <Switch onChange={switchOnChange} checked={isUseflg} />
                                        </Col>
                                        <Col span={2}></Col>
                                    </Row>
                                </Col>
                            </Row>
                    </Section>
                    </Col> */}
                </Row>

            </Form>

        </>
    )
}

export default TruckDataInfo

import http from "../lib/http";
import { rootUrl } from "./baseUrl";
// const url = "http://localhost:8080/truck/";
const url = rootUrl("FLEET")+"driver/"

const getAllDriver = () => {
  return http.get(url);
};

const getBydrvUUId = (data: string) => {
    return http.get(url + "bydrvUUId/" + data);
};

const getByCondition = (data: string) => {
    console.log(url + "byCondition/" + data)
    if(data === null || data === ''){
        data = 'null'
      }
    return http.get(url + "byCondition/" + data);
};


const addDriver = (Driver: any) => {
    console.log("addDriver >>> ", Driver)
    return http.post(url+"addDriver", Driver);
  };

  const updateDriver = (Driver: any) => {
    console.log(url + "updateDriver")
    console.log(Driver)
    return http.post(url + "updateDriver", Driver);
  };

  const getLocationAll = () => {
    return http.get(url+ "locationall");
  };

  const addSecUserDriver = (SecUser : any) => {
    console.log("addSecUserDriver >>> ", SecUser)
    return http.post(url+"addSecUserDriver", SecUser);
  };

  const updateSecUserDriver = (SecUser : any) => {
    console.log("updateSecUserDriver >>> ", SecUser)
    return http.post(url+"updateSecUserDriver", SecUser);
  };

 const getSecUserDriver = (data: string) => {
  console.log(url + "secUserByDriverUId/"+data)
    return http.get(url + "secUserByDriverUId/" + data);
 };
 

export default {
    getAllDriver,
    getBydrvUUId,
    getByCondition,
    addDriver,
    updateDriver,
    getLocationAll,
    addSecUserDriver,
    updateSecUserDriver,
    getSecUserDriver
}
import BookingHead from "./BookingHead";

class TruckType {

    id: number = -1;
    typeName: string = "";
    useFlg: number = -2;
    truckTypeId: number;
    truckTypeName: string;
    truckCount: number;
    bookingHeadList: BookingHead[];
    constructor(
        truckTypeId: number,
        truckTypeName: string,
        truckCount: number,
        bookingHeadList: BookingHead[]
    ){
        this.truckTypeId = truckTypeId;
        this.truckTypeName = truckTypeName;
        this.truckCount = truckCount;
        this.bookingHeadList = bookingHeadList;
    }
}

export default TruckType;

import { Button, Col, Form, Input, message, Row, Space, Typography } from 'antd'
import classes from "../mastercss/master.module.css";
import React, { useEffect, useState } from 'react'
import { SaveOutlined, UserOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";

const ChangePassword = () => {

  const [form] = Form.useForm();

  return (
    <>  <Form
    // form={form}
    //  layout="vertical"
    // onFinish={onFinish}
    // onFinishFailed={onFinishFailed}
    >
      <Row style={{ paddingLeft: "40px",paddingRight:"20px",paddingBottom:"20px",paddingTop:"20px"}}>
        <Col span={24}>
          <div className={classes.divAdd2}
            style={{ width: "98%", height: "400px" }}
          >
            <Row style={{ marginTop: "2em", }} gutter={10}>

            </Row>
            <Row gutter={10}>
              <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                <span>Username :</span> </Col>
              <Col span={6} >
                <Form.Item
                  name="username"
                  required tooltip="This is a required field">
                  <Input
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col span={4}
              // style={{ border: "1px solid red" }}
              ></Col>
            </Row>
            <Row gutter={10}>
              <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                <span>Old Password :</span> </Col>
              <Col span={6} >
                <Form.Item
                  name="oldPassword"
                  // label="Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={4}
              // style={{ border: "1px solid red" }}
              ></Col>
            </Row>
            <Row gutter={10}>
              <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                <span>New Password :</span> </Col>
              <Col span={6} >
                <Form.Item
                  name="newPassword"
                  // label="Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={4}
              // style={{ border: "1px solid red" }}
              ></Col>
            </Row>
            <Row gutter={10}>
              <Col span={8} style={{ textAlign: "right", fontSize: "15px" }}>
                <span>Confirm Password :</span> </Col>
              <Col span={6} >

                <Form.Item
                  name="confirmPassword"
                  // label="Confirm Password"
                  dependencies={['newPassword']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('password ไม่ตรงกัน'));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={4}
              // style={{ border: "1px solid red" }}
              >
                <Typography.Link href="#API">รีเซต</Typography.Link>

              </Col>
            </Row>
            <Row style={{ marginBottom: "2em", }}>
              <Col span={22} style={{ textAlign: "center" }}>
                <Space >
                  <Button
                    size="middle"
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={() => { form.submit() }}
                  // onClick={() => handleClick(true)}
                  >
                    บันทึก
                  </Button>
                  <Button
                    size="middle"
                    type="primary"
                    icon={<SaveOutlined />}
                  // onClick={() => setTrigger(true)}
                  >
                    ยกเลิก
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Form></>
  )
}

export default ChangePassword;
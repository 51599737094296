import React, { useEffect, useState } from "react";
import classes from "./ModernAppLayout.module.css";
import SideNavModule from "./SideNavModule";
import {  menus } from './Menu';
import { useAppSelector } from "../store";
import SideNavMenu from "./SideNavMenu";

interface IProps {
  children?: React.ReactNode;
}

const menuModules = [
  // { id: 1, name: "overview", icon: "BlockOutlined", code: "001" },
  { id: 2, name: "Job", icon: "BookOutlined", code: "002" },
  { id: 3, name: "Truck", icon: "TableOutlined", code: "003" },
  { id: 4, name: "Driver", icon: "TeamOutlined", code: "004" },
  { id: 5, name: "Partner", icon: "SecurityOutlined", code: "005" },
  { id: 6, name: "Config", icon: "SecurityOutlined", code: "006" },
];



const ModernAppLayout: React.FC<IProps> = (props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<any>({});
  const { sessionUser } = useAppSelector((state) => state.auth);


  useEffect(() => {
    const moduleItem = menus.find((module) => module.moduleCode === "002");
    setMenuItems(moduleItem?.moduleMenus);
  },[]);


  const showHandler = () => {
    setShowMenu((prev) => !prev);
  };


  const handleMenuClick = () => {
    setShowMenu(false);
  }


  const handleSelectModule = (moduleCode: string) => {
    console.log("module code", moduleCode);
    const moduleItem = menus.find((module) => module.moduleCode === moduleCode);
    setMenuItems(moduleItem?.moduleMenus);
    if (!showMenu) {
      setShowMenu(true);
    }
  };


  return (
    <div style={{ display: "flex" }}>
      <div className={classes.sideNav}>
        <SideNavModule
          showMenu={showHandler}
          onSelectModule={handleSelectModule}
          menuModules={menuModules}
          menus={menus}
        />
        {showMenu && <SideNavMenu menuItems={menuItems} onMenuClick={handleMenuClick} />}
      </div>

      <div
        style={{
          backgroundColor: "#f1f2f7",
          width: "100%",
          overflow: 'auto',
          minHeight: '100vh',
          height: "100%",
          paddingLeft: showMenu ? "285px" : "60px",
        }}
      >
        {/* { process.env.REACT_APP_BUILD_LABEL === 'Staging' && (<div style={{ width: '100%', height: '3px', backgroundColor: '#1665c0c2'}} />)}
        { process.env.REACT_APP_BUILD_LABEL === 'Dev' && (<div style={{ width: '100%', height: '3px', backgroundColor: '#c0871678'}} />)} */}
        {
          (
              process.env.REACT_APP_BUILD_LABEL === 'Dev'
            || process.env.REACT_APP_BUILD_LABEL === 'UAT'
            || process.env.REACT_APP_BUILD_LABEL === 'Staging') && (
            <div style={{display: 'flex', justifyContent: 'center', width: '100%', height: '3px',  backgroundColor: '#c0871678'}}>
              <div style={{ position: 'relative',top: '0px', textAlign: 'center', height: '20px', width: '80px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#9e6c06', backgroundColor: '#c0871678'}} >
                {process.env.REACT_APP_BUILD_LABEL}
              </div>
            </div>
          )
        }

        {/* <div className={classes.notificationBar}>
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
           <Text>Branch: <span style={{fontWeight:700 }}>"สาขา"</span></Text>
            <Text>User: <span style={{fontWeight:700 }}>"User"</span></Text>
          </div>
          <div>
            <ul className={classes.notificationContainer}>
              <li className={classes.notificationItem}>
                <Volume2 strokeWidth={1} />
              </li>
              <li className={classes.notificationItem}>
                <MessageSquare strokeWidth={1} />
              </li>
            </ul>
          </div>
        </div> */}
        <div
          style={{
            width: "100%",
            padding: "20px 40px 20px 40px",
            minHeight: '100vh',
            height: '100%',
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ModernAppLayout;

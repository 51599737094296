import { Button, Form } from "antd";
import React, { useEffect } from "react";
// import classes from "./payment.module.css";

interface IProps {
    onButtonOrder?: any;
    onButtonSMC?: any;
    onButtonStatement?: any;
    docType?: any;
}
const StepListPayment: React.FC<IProps> = (props) => {


    return (
        <>
            <Form>
                {/* className={classes["style-text-div"]} */}
                {/* className={classes["style-div"]} */}
                <div >เลือกรายการรับชำระ :</div>
                <div >
                    <Button
                        // className={classes["button"]}
                        onClick={() => {
                            if (props.onButtonOrder) {
                                props.onButtonOrder();
                            }
                        }}
                        style={{
                            backgroundColor: props.docType == "order" ? "#b30a0a" : "#224561",
                            color: "#fff",
                        }}
                    >
                        ใบส่งสินค้า
          </Button>
                </div>
                {/* className={classes["style-div"]} */}
                <div >
                    <Button
                        // className={classes["button"]}
                        onClick={() => {
                            if (props.onButtonSMC) {
                                props.onButtonSMC();
                            }
                        }}
                        style={{
                            backgroundColor: props.docType == "smc" ? "#b30a0a" : "#224561",
                            color: "#fff",
                        }}
                    >
                        รถกระจาย
          </Button>
                </div>
                {/* className={classes["style-div"]} */}
                <div >
                    <Button
                        // className={classes["button"]}
                        onClick={() => {
                            if (props.onButtonStatement) {
                                props.onButtonStatement();
                            }
                        }}
                        style={{
                            backgroundColor:
                                props.docType == "statement" ? "#b30a0a" : "#224561",
                            color: "#fff",
                        }}
                    >
                        ใบวางบิล
          </Button>
                </div>
            </Form>
        </>
    );
};

export default StepListPayment;
import React, { useEffect, useState } from 'react';
import { Alert, Button, Layout, notification, Table, Tabs } from 'antd';
import { ColumnsType } from "antd/lib/table";
import PageHeader from 'src/layouts/PageHeader';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import BookingHead from 'src/model/truckmatching/BookingHead';
import { TableRowSelection } from "antd/lib/table/interface";
import Flex from 'src/components/container/Flex';
import truckMatchService from 'src/services/truckMatchService';
import sendBookingService from 'src/services/sendBookingService';
import PartnerTruckDTO from 'src/model/truckmatching/PartnerTruckDTO';
import Partner from 'src/model/truckmatching/Partner';
import TruckTypeDTO from 'src/model/truckmatching/TruckTypeDTO';
import RmSendBookHeadDTO from 'src/model/truckmatching/RmSendBookHeadDTO';
import RmSendBookDetailDTO from 'src/model/truckmatching/RmSendBookDetailDTO';
import dateUtils from 'src/utils/dateUtils';

const SelectPartner = (props: any) => {

    const history = useHistory();
    const [pageSize, setPageSize] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentJobList, setCurrentJobList] = useState<BookingHead[]>([]);
    const [selectedBookingHeadsId, setSelectedBookingHeadsId] = useState<number[]>([]);
    const [selectedBookingHeads, setSelectedBookingHeads] = useState<BookingHead[]>([]);
    const [partnerTrucks, setPartnerTrucks] = useState<PartnerTruckDTO[]>([]);
    const [columns, setColumns] = useState<ColumnsType<PartnerTruckDTO>>();
    const [truckTypeKeys, setTruckTypeKeys] = useState<string[]>([]);

    const constantColumns: ColumnsType<PartnerTruckDTO> = [
        {
            title: "Partner",
            dataIndex: "partner",
            key: "partnerName",
            render: (value: Partner, item: PartnerTruckDTO, index: number) => (
                value.name
            ),
        },
    ];

    useEffect(() => {
        console.log("props: ", props);
        if(props.history.location.state && props.history.location.state.state.length > 0){
            setSelectedBookingHeadsId(props.history.location.state.state);
        }else{
            history.replace("/app/send2partner/list");
        }
    }, []);

    useEffect(() => {
        (async() => {
            if(selectedBookingHeadsId.length > 0){
                console.log("ssssssssssssssss")
                let res = await truckMatchService.getPartnerByTruckGroupInBookingHeads(selectedBookingHeadsId);
                console.log(res.data);
                setPartnerTrucks(res.data);
                let nowColumns = [...constantColumns]
                nowColumns = nowColumns.concat(createTruckTypeColumns(res.data));
                setColumns(nowColumns);
            }
        })();
        (async() => {
            if(selectedBookingHeadsId.length > 0){
                let res = await truckMatchService.getByIdList(selectedBookingHeadsId);
                setSelectedBookingHeads(res.data);
            }
        })();
    }, [selectedBookingHeadsId])

    useEffect(() => {
        let defaultSelectedRowKeys: number[] = [];
        let defaultSelectedRows: PartnerTruckDTO[] = [];
        for(let i = 0; i < partnerTrucks.length; i++){
            defaultSelectedRowKeys.push(partnerTrucks[i].partner.id);
            defaultSelectedRows.push(partnerTrucks[i]);
        }
        setSelectedRowKeys(defaultSelectedRowKeys);
        setSelectedRows(defaultSelectedRows);
    }, [partnerTrucks]);

    const createTruckTypeColumns = (responseList: PartnerTruckDTO[]) => {
        let resultColumns: Array<any> = [];
        let columnMap: any = [];
        let listOfColumnKeys: string[] = [];
        for(let i = 0; i < responseList.length; i++){
            let rowData = responseList[i];
            let truckTypeList = rowData.truckTypeList;
            for(let j = 0; j < truckTypeList.length; j++){
                const truckType = truckTypeList[j];
                const truckTypeKey = rowData.truckTypeMapKeyPrefix + truckType.truckType.id;
                // console.log("truckType: ", truckType);

                if(!columnMap[truckTypeKey]){
                    // console.log("truckTypeKey: ", truckTypeKey);
                    listOfColumnKeys.push(truckTypeKey);
                    let newColumn = {
                        title: truckType.truckType.typeName,
                        dataIndex: "truckTypeMap",
                        key: "truckTypeMap_" + truckType.truckType.id,
                        width: 120,
                        render: (value: any, item: PartnerTruckDTO, index: number) => {
                            // console.log("truckTypeMap_" + truckType.truckType.id, value);
                            let truckTypeData: TruckTypeDTO = value[truckTypeKey];
                            console.log("value[" + truckTypeKey + "]: ", truckTypeData);
                            if(truckTypeData && truckTypeData.countTruck){
                                return (
                                    <a onClick={() => {history.push("/app/send2partner/view", {bookingHeadIds: selectedBookingHeadsId, partnerId: item.partner.id, truckTypeId: truckTypeData.truckType.id})}}>{truckTypeData.countTruck}</a>
                                )
                            }
                        },
                    }
                    resultColumns.push(newColumn);
                    columnMap[truckTypeKey] = "ok";
                }
            }
        }
        setTruckTypeKeys(listOfColumnKeys);
        return resultColumns;
    }

    const sendBooking2Partner = async () => {
        let rmSendBooking: RmSendBookHeadDTO = await createSendBookHead();
        console.log("rmSendBooking: ", rmSendBooking);
        // truckMatchService.
        let res = await sendBookingService.sendJob2Partner(rmSendBooking);
        return res.data;
    }
    const createSendBookHead = async () => {
        let detailList: RmSendBookDetailDTO[] = await createSendBookDetail();
        let result: RmSendBookHeadDTO = {
            id: 0,
            sendDate: dateUtils.convertDate2DBDateFormat(new Date()),
            sendTime: dateUtils.convertDate2DBTimeFormat(new Date()),
            bookQty: selectedBookingHeadsId.length,
            rmSendBookDetailDTOs: detailList,
            createUserid: 0,
            createUsername: "TestAdmin",
            updateUserid: 0,
            updateUsername: "TestAdmin",
        }

        return result;
    }
    const createSendBookDetail = async () => {
        let resultList: RmSendBookDetailDTO[] = [];
        for(let i = 0; i < selectedBookingHeads.length; i++){
            let selectedBookingHead = selectedBookingHeads[i];
            for(let j = 0; j < selectedRows.length; j++){
                let selectedRow = selectedRows[j];
                console.log("selectedRow: ", selectedRow.partner.name);
                let truckTypeKey = selectedRow.truckTypeMapKeyPrefix + selectedBookingHead.truckTypeId;
                if(selectedRow.truckTypeMap[truckTypeKey]){
                    let result: RmSendBookDetailDTO = {
                        id: 0,
                        rmSendbookHeadId: selectedBookingHeads[i].id,
                        partnerUId: selectedRow.partner.partnerUId,
                        bookNo: selectedBookingHeads[i].bookNo,
                        partnerName: selectedRow.partner.name,
                        createUserid: 0,
                        createUsername: "TestAdmin",
                        updateUserid: 0,
                        updateUsername: "TestAdmin",
                    }
                    resultList.push(result);
                }
            }
        }
        return resultList;
    }

    const [selectedRowKeys, setSelectedRowKeys] = useState<any>();
    const [selectedRows, setSelectedRows] = useState<PartnerTruckDTO[]>([]);
    const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: PartnerTruckDTO[]) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        console.log('selectedRows changed: ', selectedRows);
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
    };
    const rowSelection: TableRowSelection<PartnerTruckDTO> = {selectedRowKeys, onChange: onSelectChange};

    return (
        <div>
            <PageHeader title="เลือก Partner">
            </PageHeader>
            <Content className="app-page-content" style={{paddingLeft: 5, paddingRight: 5}}>
                <Flex flexDirection='column' justifyContent="center" alignItems="center">
                    <div style={{marginTop: 10, fontSize: 30}}>งานวิ่งรถ {selectedBookingHeadsId.length} รายการ</div>
                    <div style={{marginTop: 5, display: 'flex', flexDirection: 'row', width: '90%'}}>
                        <Table
                            columns={columns}
                            dataSource={partnerTrucks}
                            size="small"
                            rowKey={(item: PartnerTruckDTO) => item.partner.id}
                            rowSelection={rowSelection}
                            pagination={{current: currentPage, pageSize: pageSize, onChange: (page: number) => {
                                setCurrentPage(page);
                            }}}
                            style={{width: '100%'}}
                        />
                    </div>
                    <div style={{margin: 5}}>
                        <Button
                            size="small"
                            type="primary"
                            // icon={<PlusOutlined />}
                            onClick={async () => { 
                                try{
                                    if(selectedRowKeys && selectedRowKeys.length > 0){
                                        console.log({bookingHeadIds: selectedBookingHeadsId, partnerIds: selectedRowKeys});
                                        let result: boolean = await sendBooking2Partner();
                                        if(result){
                                            notification.success({message: "ส่งงานให้ Partner สำเร็จ"});
                                            history.replace("/app/send2partner/list");
                                        }else{
                                            notification.error({message: "ส่งงานให้ Partner ไม่สำเร็จ"});
                                        }
                                    }else{
                                        notification.warning({message: "กรุณาเลือก Partner อย่างน้อย 1 Partner"});
                                    }
                                }catch(ex){
                                    notification.error({message: "ส่งงานให้ Partner ไม่สำเร็จ"});
                                }
                            }} 
                            >ตกลง
                        </Button>
                        &nbsp;
                        <Button
                            size="small"
                            type="default"
                            // icon={<PlusOutlined />}
                            onClick={() => { history.replace("/app/send2partner/list") }} 
                            >ยกเลิก
                        </Button>
                    </div>
                </Flex>
            </Content>
        </div>
    )
}

export default SelectPartner;
